import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { ModalMaterial } from "../../modals/Material";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import {
  deleteMaterial,
  editMaterial,
  getMaterials,
  getMaterialsByCenter,
} from "../../../redux/actions/material";
import { store } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import CSVDownloader from "../../ui/CSVDownloader";
import { getToday } from "../../ui/DateUtils";
import { Button } from "@mui/material";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  addMaterialToProtocol,
  getCenterProtocols,
  getProtocols,
} from "../../../services/protocolService";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../ui/utils/AlertUtils";

const SPACING = 50;

export const ScreenMaterials = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const studyCode = location.state?.studyCode;
  const centerNumber = location.state?.centerNumber;
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const centerUser = user.organization === "Center";
  const isLoading = useSelector((state) => state.material.isLoadingMaterials);
  const studyId = location.state?.studyId;
  const centerId = location.state?.centerId;
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedCell, setSelectedCell] = React.useState(null);
  const [name, setName] = React.useState("");
  const [type, setType] = React.useState("");
  const [count, setCount] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [showProtocolSelector, setShowProtocolSelector] = React.useState(false);
  const [protocols, setProtocols] = React.useState([]);
  const [centerProtocols, setCenterProtocols] = React.useState([]);
  const [selectedProtocol, setSelectedProtocol] = React.useState("");
  const materials = useSelector((state) => state.material.materials);
  const updatedMaterialState = useSelector(
    (state) => state.material.updatedMaterial
  );
  const deletedMaterial = useSelector(
    (state) => state.material.deletedMaterial
  );
  const [updatedProtocolMaterials, setUpdatedProtocolMaterials] =
    React.useState(false);

  const handleAddMaterialToProtocol = async (protocolId) => {
    const materialIds = selectedRows;
    materialIds.forEach((materialId) => {
      if (materialId) {
        addMaterialToProtocol(protocolId, materialId);
      }
    });
  };

  const handleProtocolSelect = (event) => {
    const protocolId = event.target.value;
    setSelectedProtocol(protocolId);
  };

  const handleConfirm = () => {
    if (selectedProtocol) {
      handleAddMaterialToProtocol(selectedProtocol);
      setShowProtocolSelector(false);
      showSuccessAlert(
        "Materials have successfully been added to the protocol."
      );
      setUpdatedProtocolMaterials(true);
    }
  };

  const handleCellDoubleClick = (params) => {
    if (params.field === "name") {
      setSelectedCell(params.id);
      const selectedMaterial = materials.find(
        (material) => material.id === params.id
      );
      setName(selectedMaterial.name);
      setType(selectedMaterial.type);
      setStatus(selectedMaterial.status);
      setCount(selectedMaterial.totalCount);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteMaterial = async () => {
    setOpen(false);
    try {
      const result = await showWarningAlert(
        "Are you sure you want to delete this material?"
      );
      if (result.isConfirmed) {
        await store.dispatch(deleteMaterial(selectedCell));
        showSuccessAlert("Material successfully deleted");
      }
    } catch (error) {
      console.error(error);
      showErrorAlert("Failed to delete material");
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const updatedMaterial = {
      name: name,
      type: type,
      status: status,
    };
    setOpen(false);
    try {
      const response = await store.dispatch(
        editMaterial(selectedCell, updatedMaterial)
      );
      if (response) {
        showSuccessAlert("Material successfully updated");
      } else {
        showErrorAlert("Failed to update material");
      }
    } catch (error) {
      console.error(error);
      showErrorAlert("Failed to update material");
    }
  };

  const sortedMaterials = Array.isArray(materials)
    ? [...materials]
        .filter((material) => material.type === "material")
        .sort((a, b) => {
          const idA = a.Protocols?.[0]?.id ?? 0;
          const idB = b.Protocols?.[0]?.id ?? 0;
          if (idA !== idB) {
            return idB - idA;
          }
          return a.name.localeCompare(b.name);
        })
        .concat(materials.filter((material) => material.type !== "material"))
    : [];

  const columns = React.useMemo(() => {
    if (centerId) {
      return [
        { field: "name", headerName: "MATERIAL", width: 500 },
        {
          field: "productionCount",
          headerName: "Stock",
          width: 100,
        },
        {
          field: "notes",
          headerName: "",
          width: 400,
          renderCell: (params) => (
            <div
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={() =>
                navigate(`/productions`, {
                  state: {
                    centerId: centerId,
                    centerNumber: centerNumber,
                    studyId: studyId,
                    studyCode: studyCode,
                    materialId: params.row.id,
                    expiring: true,
                  },
                })
              }
            >
              {params.value}
            </div>
          ),
        },
        {
          field: "protocols",
          headerName: "Protocol",
          width: 200,
          renderCell: (params) => {
            if (params.value) {
              return params.value
                .map((protocol) => protocol.reference)
                .join(", ");
            } else {
              return "";
            }
          },
        },
      ];
    } else {
      if (!centerUser) {
        return [
          { field: "name", headerName: "Material Type", width: 500 },
          { field: "availableCount", headerName: "Available", width: 100 },
          { field: "inTransitCount", headerName: "In Transit", width: 100 },
          {
            field: "atCenterCount",
            headerName: "At Center",
            width: 100,
          },
          {
            field: "attributedCount",
            headerName: "Attributed",
            width: 100,
            valueGetter: (params) =>
              params.row.type === "material" ? params.value : "NA",
          },
          {
            field: "destructionCount",
            headerName: "Destroyed",
            width: 100,
          },
          { field: "totalCount", headerName: "Total", width: 100 },
          {
            field: "Protocols",
            headerName: "Used in protocols",
            width: 200,
            renderCell: (params) => {
              if (params.value) {
                return params.value
                  .map((protocol) => protocol.reference)
                  .join(", ");
              } else {
                return "Loading...";
              }
            },
          },
        ];
      } else {
        return [{ field: "name", headerName: "MATERIAL", width: 500 }];
      }
    }
  }, [centerId, centerUser, centerNumber, studyId, studyCode, navigate]);

  // Extract the field values from the columns array
  const visibleColumns = columns.map((column) => column.field);
  const headerMapping = columns.reduce((acc, column) => {
    acc[column.field] = column.headerName;
    return acc;
  }, {});
  headerMapping["Protocols"] = "Used in protocols";

  // Filter the data based on the visible columns and replace keys with headerName
  const materialsToDownload =
    !centerUser && selectedRows.length > 0
      ? sortedMaterials.filter((material) => selectedRows.includes(material.id))
      : sortedMaterials;

  const filteredMaterials = materialsToDownload.map((row) => {
    const filteredRow = {};
    for (const key in row) {
           if (key === "protocols" || key === "Protocols") {
        // Map over the array and join the elements into a string
        filteredRow[headerMapping[key]] = row[key]
          .map((protocol) => protocol.reference)
          .join(", ");
      } else if (visibleColumns.includes(key)) {
        filteredRow[headerMapping[key]] = row[key];
      }
    }
    return filteredRow;
  });

  // Fetch materials when a material is updated
  React.useEffect(() => {
    const fetchData = async () => {
      if (centerId) {
        await store.dispatch(getMaterialsByCenter(centerId));
      } else if (studyId) {
        await store.dispatch(getMaterials(studyId));
      }
    };
    fetchData();
  }, [updatedMaterialState, updatedProtocolMaterials, deletedMaterial, centerId, studyId]);

  // Fetch materials
  React.useEffect(() => {
    const fetchData = async () => {
      if (centerId) {
        await store.dispatch(getMaterialsByCenter(centerId));
      } else if (studyId) {
        await store.dispatch(getMaterials(studyId));
      } else {
        navigate("/studies");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, centerId]);

  // Fetch protocols
  React.useEffect(() => {
    if (showProtocolSelector) {
      getProtocols(studyId).then((response) => {
        setProtocols(response);
      });
    }
  }, [showProtocolSelector, studyId]);

  // Fetch center protocols
  React.useEffect(() => {
    if (centerId) {
      getCenterProtocols(centerId).then((response) => {
        setCenterProtocols(response);
      });
    }
  }, [centerId]);

  // Set the selected protocol to the first protocol in the list by default
  React.useEffect(() => {
    if (protocols.length > 0) {
      setSelectedProtocol(protocols[0].id);
    } else {
      setSelectedProtocol("");
    }
  }, [protocols]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {centerId && (
          <div
            style={{
              color: "grey",
              textAlign: "right",
              padding: "10px",
            }}
          >
            Current protocols applicable:{" "}
            {centerProtocols.map((protocol) => protocol.reference).join(", ")}
          </div>
        )}
        <div style={{ width: "100%", height: "80vh" }}>
          {materials ? (
            <DataGrid
              autoWidth
              slots={{
                noRowsOverlay: NoRowOverlay,
                loadingOverlay: LinearProgress,
              }}
              rows={sortedMaterials}
              columns={columns}
              loading={isLoading}
              hideFooter
              checkboxSelection={!centerUser && !centerId}
              onRowSelectionModelChange={(selection) => {
                setSelectedRows(selection);
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[50, 100]}
              rowHeight={30}
              onCellDoubleClick={
                adminUser && !centerId ? handleCellDoubleClick : null
              }
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div>
          <Dialog
            PaperProps={{
              style: { padding: "20px" },
            }}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Edit Material Type</DialogTitle>
            <DialogContent>
              <form onSubmit={handleEdit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="name"
                      name="name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      label="Name"
                      style={{ marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="type-label">Type</InputLabel>

                      <Select
                        label="type"
                        labelId="type-label"
                        id="type"
                        name="type"
                        value={type}
                        onChange={(event) => setType(event.target.value)}
                        required
                      >
                        <MenuItem value={"material"}>Kit</MenuItem>
                        <MenuItem value={"supply"}>Supply</MenuItem>
                        <MenuItem value={"reagent"}>Reagent</MenuItem>
                        <MenuItem value={"document"}>Document</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        labelId="status-label"
                        label="status"
                        id="status"
                        name="status"
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                        required
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"finished"}>Finished</MenuItem>
                        <MenuItem value={"planned"}>Planned</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {count === 0 ? (
                  <Button
                    onClick={handleDeleteMaterial}
                    color="secondary"
                    variant="contained"
                    style={{ marginTop: "20px" }}
                  >
                    Delete Material
                  </Button>
                ) : null}
              </form>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleEdit} color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <CSVDownloader
            data={filteredMaterials}
            filename={`${studyCode}${
              centerNumber ? `_${centerNumber}` : ""
            }_materials_${getToday()}`}
            name="material list"
          />
          {!centerId && adminUser && selectedRows.length > 0 && (
            <Button
              type="submit"
              disabled={isLoading}
              onClick={() => setShowProtocolSelector(true)}
              variant="contained"
              color="primary"
              size="large"
              style={{ padding: "16px 88px", margin: "16px" }}
            >
              Add to a protocol
            </Button>
          )}

          <Dialog
            open={showProtocolSelector}
            onClose={() => setShowProtocolSelector(false)}
            PaperProps={{
              style: { padding: "20px" },
            }}
          >
            <DialogTitle style={{ marginBottom: "4px" }}>
              Add Materials to a Protocol
            </DialogTitle>
            <DialogContent>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel id="protocol-select-label">Protocol</InputLabel>
                <Select
                  labelId="protocol-select-label"
                  label="Protocol"
                  value={selectedProtocol || protocols[0]?.id}
                  onChange={handleProtocolSelect}
                >
                  {protocols.map((protocol) => (
                    <MenuItem key={protocol.id} value={protocol.id}>
                      {protocol.reference}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowProtocolSelector(false)}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                color="primary"
                variant="contained"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
      {adminUser && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setVisibleCreateEdit(true);
            }}
            style={{ position: "absolute", right: SPACING, bottom: SPACING }}
          >
            <AddIcon />
          </Fab>
          <ModalMaterial
            visible={visibleCreateEdit}
            studyId={studyId}
            studyCode={studyCode}
            onClose={(refresh) => {
              setVisibleCreateEdit(false);
              if (refresh) {
                store.dispatch(getMaterials(studyId));
              }
            }}
          ></ModalMaterial>
        </>
      )}
    </Grid>
  );
};
