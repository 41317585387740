import axios from "axios";
import getConfig from "./config";
import { store } from "./store";
import { ROOT } from "./redux/constants";

let config = getConfig();
let baseUrl = config.PROTOCOL + config.SERVER + ":" + config.PORT;

const instance = axios.create({
  baseURL: baseUrl + "/" + config.API_VERSION,
});

instance.interceptors.request.use(
  (config) => {
    if (store.getState().root.tokenAccess)
      config.headers["Authorization"] =
        "Bearer " + store.getState().root.tokenAccess;
    config.headers["Content-Type"] = "application/json";
    config.timeout = 3000;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401) {
        if (!originalRequest._retry) {
          let response = await instance.post(baseUrl + "/token/refresh", {
            user_id: store.getState().user.user.id,
          });
          let accessToken = response.data;
          store.dispatch({
            type: ROOT.UPDATE_TOKEN,
            payload: accessToken,
          });
          originalRequest.headers["Authorization"] = "Bearer " + accessToken;
          originalRequest._retry = true;
          instance.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken;
          return axios(originalRequest);
        } else {
          store.dispatch({
            type: ROOT.LOGOUT,
          });
          return Promise.reject(error);
        }
      }
      store.dispatch({
        type: ROOT.LOGOUT,
      });
      return Promise.reject(error);
    } catch (error) {
      store.dispatch({
        type: ROOT.LOGOUT,
      });
    }
  }
);

export { instance, baseUrl };
