import { Grid, Paper, Box, TextField } from "@mui/material";
import { LinearProgress, Button, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReadOnlyTextField from "../../ui/ReadOnlyTextField";
import { store } from "../../../store";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { getOrder } from "../../../redux/actions/order";
import OrderTable from "../../ui/OrderTable";
import { getProductionsByMaterial } from "../../../redux/actions/production";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { fetchProductionsByOrderId } from "../../../services/productionService";

export const ScreenCreateShippingForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderId = location.state?.orderId;
  const order = useSelector((state) => state.order.order);
  const initialShipment = location.state?.newShipment || {};
  const [productions, setProductions] = React.useState({});
  const [productionsSent, setProductionsSent] = React.useState(
    initialShipment.productionsSent || {}
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const centerNumber = location.state?.centerNumber;
  const studyCode = location.state?.studyCode;
  const today = new Date().toISOString().split("T")[0];

  const [carrierOrderDate, setCarrierOrderDate] = React.useState(
    initialShipment.carrierOrderDate || today
  );
  const [sendingDate, setSendingDate] = React.useState(
    initialShipment.sendingDate || today
  );
  const [preparationDate, setPreparationDate] = React.useState(
    initialShipment.preparationDate || today
  );
  const [numPackages, setNumPackages] = React.useState(
    Number(initialShipment.numPackages) || ""
  );
  const [packages, setPackages] = React.useState(
    initialShipment.packages || []
  );
  const [packingSlip, setPackingSlip] = React.useState(
    initialShipment.packingSlip || ""
  );
  const [carrier, setCarrier] = React.useState(initialShipment.carrier || "");
  const [comment, setComment] = React.useState(initialShipment.comment || "");
  const [shippedQuantity, setShippedQuantity] = React.useState(
    initialShipment.shippedQuantity || 0
  );
  const [totalSentByMaterial, setTotalSentByMaterial] = React.useState({});
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const addressParts = [
    order?.hospital,
    order?.ward,
    order?.building ? `Building: ${order.building}` : null,
    order?.street,
    order?.floor ? `Floor: ${order.floor}` : null,
    order?.zipcode,
    order?.shippingAddress,
  ];
  const filteredAddress = addressParts
    .filter((part) => part && part.trim() !== "")
    .join(", ");

  const handleClickOpen = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: true }));
  };

  const handleClose = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: false }));
  };

  // function to calculate the total quantity of productions already sent for this shipment
  React.useEffect(() => {
    const getTotalSentByMaterial = async () => {
      const productions = await fetchProductionsByOrderId(orderId);

      const totalSent = {};

      for (const production of productions) {
        if (!totalSent[production.materialId]) {
          totalSent[production.materialId] = 1;
        } else {
          totalSent[production.materialId]++;
        }
      }

      setTotalSentByMaterial(totalSent);
    };

    getTotalSentByMaterial();
  }, [orderId]);

  // function to calculate the total quantity of productions sent for this shipment
  React.useEffect(() => {
    let total = 0;
    for (let key in productionsSent) {
      total += productionsSent[key].length;
    }
    setShippedQuantity(total);
  }, [productionsSent]);

  // function to have the dimensions of the packages
  const handleDimensionChange = (index, dimension, value) => {
    const newPackages = [...packages];
    newPackages[index] = {
      ...newPackages[index],
      [dimension]: Number(value),
    };
    if (
      newPackages[index].length &&
      newPackages[index].height &&
      newPackages[index].width
    ) {
      newPackages[index].volumetricWeight =
        (newPackages[index].length *
          newPackages[index].height *
          newPackages[index].width) /
        5000;
    }
    setPackages(newPackages);
  };

  const handleNumPackagesChange = (event) => {
    setNumPackages(event.target.value);
  };

  const validateShipment = (shipment) => {
    const requiredFields = [
      "carrier",
      "packingSlip",
      "numPackages",
      "packages",
    ];

    // Check if productionsSent is an object and contains at least one object in one of its arrays
    if (
      typeof shipment.productionsSent !== "object" ||
      !Object.values(shipment.productionsSent).some((array) => array.length > 0)
    ) {
      return false;
    }

    for (let field of requiredFields) {
      if (
        !shipment[field] ||
        (Array.isArray(shipment[field]) && shipment[field].length === 0)
      ) {
        return false;
      }
    }

    return true;
  };

  // action for sending shipment button
  const handleNewShipment = () => {
    const newShipment = {
      productionsSent: productionsSent,
      sendingDate: sendingDate,
      packingSlip: packingSlip,
      carrier: carrier,
      carrierOrderDate: carrierOrderDate,
      preparationDate: preparationDate,
      numPackages: numPackages,
      packages: packages,
      shippedQuantity: shippedQuantity,
      comment: comment,
      orderId: orderId,
    };

    if (!validateShipment(newShipment)) {
      setShowErrorMessage(true);
      return;
    }

    setShowErrorMessage(false);

    navigate("/shipping/confirm", {
      state: { newShipment, orderId, studyCode, centerNumber },
    });
  };

  const materialsTypeMaterial = order.Supplies.filter(
    (supply) => supply.Material.type === "material"
  );
  const materialsTypeSupply = order.Supplies.filter(
    (supply) => supply.Material.type === "supply"
  );
  const materialsTypeReagent = order.Supplies.filter(
    (supply) => supply.Material.type === "reagent"
  );
  const materialsTypeDocuments = order.Supplies.filter(
    (supply) => supply.Material.type === "document"
  );

  const CustomDataGrid = ({ rows, headerName }) => {
    const columns = [
      {
        field: "materialName",
        headerName: headerName,
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.Material.name,
      },
      {
        field: "quantity",
        headerName: "Needed Quantity",
        flex: 0.08,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => {
          const totalNeeded = params.row.quantity;
          const totalSent = totalSentByMaterial[params.row.Material.id] || 0;
          const remainingNeeded = totalNeeded - totalSent;
          return remainingNeeded > 0 ? remainingNeeded : 0;
        },
      },
      {
        field: "shippingQuantity",
        headerName: "Sent Quantity",
        flex: 0.08,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <div>{(productionsSent[params.row.Material.id] || []).length}</div>
          );
        },
      },
      // References field to pick productions from the stock to select to send
      {
        field: "productions",
        headerName: "Available Productions",
        flex: 0.12,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const totalNeeded = params.row.quantity;
          const totalAlreadySent =
            totalSentByMaterial[params.row.Material.id] || 0;
          const neededQuantity = totalNeeded - totalAlreadySent;
          const totalSent = (productionsSent[params.row.Material.id] || [])
            .length;

          const selectedProductions =
            productionsSent[params.row.Material.id] || [];
          const options = (productions[params.row.Material.id] || []).filter(
            (option) =>
              !selectedProductions.find((selected) => selected.id === option.id)
          );
          if (options.length === 0) {
            return (
              <div
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                No production available at CDL
              </div>
            );
          }
          return (
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClickOpen(params.row.Material.id)}
                disabled={neededQuantity <= totalSent}
              >
                Select Options
              </Button>
              <Dialog
                open={open[params.row.Material.id] || false}
                onClose={() => handleClose(params.row.Material.id)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Select Productions to Send
                </DialogTitle>
                <DialogContent>
                  <FormGroup>
                    {options
                      .sort((a, b) => a.reference - b.reference) // sort options in ascending order
                      .map((option, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={selectedProductions.some(
                                (selected) => selected.id === option.id
                              )}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setProductionsSent({
                                    ...productionsSent,
                                    [params.row.Material.id]: [
                                      ...(productionsSent[
                                        params.row.Material.id
                                      ] || []),
                                      option,
                                    ],
                                  });

                                  // Check if totalSent equals neededQuantity after the option is added
                                  if (totalSent + 1 >= neededQuantity) {
                                    handleClose(params.row.Material.id);
                                  }
                                } else {
                                  setProductionsSent({
                                    ...productionsSent,
                                    [params.row.Material.id]: (
                                      productionsSent[params.row.Material.id] ||
                                      []
                                    ).filter(
                                      (selected) => selected.id !== option.id
                                    ),
                                  });
                                }
                              }}
                              disabled={totalSent >= neededQuantity}
                            />
                          }
                          label={`(${option.batchNumber}) ${option.reference}`}
                        />
                      ))}
                  </FormGroup>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleClose(params.row.Material.id)}
                    color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          );
        },
      },
      {
        field: "selectedProductions",
        headerName: "Selected Productions",
        flex: 0.3,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const selectedProductions = (
            productionsSent[params.row.Material.id] || []
          )
            .map(
              (production) =>
                `(${production.batchNumber}) ${production.reference}`
            )
            .join(", ");
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              <div style={{ marginRight: "0.5rem", flex: 1 }}>
                {selectedProductions}
              </div>
              {(productionsSent[params.row.Material.id] || []).length > 0 && (
                <CloseIcon
                  onClick={() => {
                    setProductionsSent({
                      ...productionsSent,
                      [params.row.Material.id]: [],
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "darkblue",
                    borderRadius: "50%",
                    fontSize: "0.9rem",
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];

    if (rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[30, 50]}
        disableSelectionOnClick
        hideFooter
        autoHeight
        rowHeight={80}
        onEditCellChangeCommitted={(params) => {
          if (
            params.field === "shippingQuantity" &&
            isNaN(Number(params.props.value))
          ) {
            alert("Please enter a valid number");
          }
        }}
      />
    );
  };

  // fetch order
  React.useEffect(() => {
    store.dispatch(getOrder(orderId)).then(() => setIsLoading(false));
  }, [orderId]);

  // Fetch available productions
  React.useEffect(() => {
    if (order) {
      order.Supplies.forEach((supply) => {
        store
          .dispatch(getProductionsByMaterial(supply.Material.id))
          .then((productions) => {
            setProductions((prevProductions) => ({
              ...prevProductions,
              [supply.Material.id]: productions,
            }));
          });
      });
    }
  }, [order]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "auto" }}>
          <OrderTable
            order={order}
            studyCode={studyCode}
            centerNumber={centerNumber}
          />
          <ReadOnlyTextField
            title="SHIPPING ADDRESS"
            value={filteredAddress}
            multiline
          />

          <Box sx={{ p: 2 }}>
            <div style={{ width: "100%" }}>
              <TextField
                value="MATERIAL ORDER"
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                fullWidth
              />
              <CustomDataGrid
                rows={materialsTypeMaterial}
                headerName="Material"
              />
              <CustomDataGrid rows={materialsTypeSupply} headerName="Supply" />
              <CustomDataGrid
                rows={materialsTypeReagent}
                headerName="Reagent"
              />
              <CustomDataGrid
                rows={materialsTypeDocuments}
                headerName="Document"
              />
            </div>
          </Box>
        </div>
        <Box sx={{ p: 2 }}>
          <TextField
            value="SHIPPING INFORMATION"
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            fullWidth
          />

          <Grid container spacing={2} style={{ marginTop: "12px" }}>
            <Grid item xs={4}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Preparation Date"
                  name="preparationDate"
                  value={dayjs(preparationDate)}
                  onChange={(newValue) => {
                    setPreparationDate(newValue.format("YYYY-MM-DD"));
                  }}
                  format="DD/MM/YYYY"
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Carrier Order Date"
                  name="carrierOrderDate"
                  value={dayjs(carrierOrderDate)}
                  onChange={(newValue) => {
                    setCarrierOrderDate(newValue.format("YYYY-MM-DD"));
                  }}
                  format="DD/MM/YYYY"
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Sending Date"
                  name="sendingDate"
                  value={dayjs(sendingDate)}
                  onChange={(newValue) => {
                    setSendingDate(newValue.format("YYYY-MM-DD"));
                  }}
                  format="DD/MM/YYYY"
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Carrier Name</InputLabel>
                <Select
                  label="Carrier Name"
                  value={carrier}
                  onChange={(e) => setCarrier(e.target.value)}
                  name="carrier"
                >
                  <MenuItem value="Area Time">Area Time</MenuItem>
                  <MenuItem value="GoPak">GoPak</MenuItem>
                  <MenuItem value="Marken">Marken</MenuItem>
                  <MenuItem value="QuickSTAT">QuickSTAT</MenuItem>
                  <MenuItem value="Transcourrier">Transcourrier</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Air Waybill"
                name="packingSlip"
                value={packingSlip}
                onChange={(e) => setPackingSlip(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Number of Packages"
                type="number"
                name="numPackages"
                value={numPackages}
                onChange={handleNumPackagesChange}
                fullWidth
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "8px" }}
          >
            {Array.from({ length: numPackages }, (_, index) => (
              <React.Fragment key={index}>
                <Grid item xs={3}>
                  <Typography variant="body1">Package {index + 1}:</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Length"
                    name="length"
                    type="number"
                    value={packages[index] ? packages[index].length : ""}
                    onChange={(e) =>
                      handleDimensionChange(index, "length", e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    inputProps={{ min: 0 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Height"
                    name="height"
                    type="number"
                    value={packages[index] ? packages[index].height : ""}
                    onChange={(e) =>
                      handleDimensionChange(index, "height", e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    inputProps={{ min: 0 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Width"
                    name="width"
                    type="number"
                    value={packages[index] ? packages[index].width : ""}
                    onChange={(e) =>
                      handleDimensionChange(index, "width", e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    inputProps={{ min: 0 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Volumetric Weight"
                    name="volumetricWeight"
                    value={
                      (packages[index] && packages[index].volumetricWeight) || 0
                    }
                    type="number"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">kg</InputAdornment>
                      ),
                    }}
                    fullWidth
                    disabled
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <TextField
          id="comment"
          name="comment"
          label="Comment"
          multiline
          rows={3}
          sx={{ m: 2 }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleNewShipment}
            style={{ padding: "16px 88px", margin: "16px" }}
          >
            Send shipping information
          </Button>
        </Grid>{" "}
        {showErrorMessage && (
          <p
            style={{
              color: "#721c24",
              backgroundColor: "#f8d7da",
              borderColor: "#f5c6cb",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "12px",
              marginLeft: "16px",
              marginRight: "16px",
              marginTop: "0px",
              textAlign: "center",
            }}
          >
            Please fill all the required fields
          </p>
        )}
      </Paper>
    </Grid>
  );
};
