import { MATERIAL } from "../constants";
import { instance } from "../../axios";

let getMaterials = (studyId) => {
  return (dispatch) => {
    dispatch({
      type: MATERIAL.MATERIAL_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/material/study/${studyId}/stock`,
    })
      .then((results) => {
        dispatch({
          type: MATERIAL.MATERIAL_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: MATERIAL.MATERIAL_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getMaterialsForOrder = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: MATERIAL.MATERIAL_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/material/center/${centerId}/order`,
    })
      .then((results) => {
        const materialsWithQuantity = results.data.map((material) => ({
          ...material,
          quantity: 0, // 0 quantity by default
        }));
        dispatch({
          type: MATERIAL.MATERIAL_GET_ALL_SUCCESS,
          payload: materialsWithQuantity,
        });
      })
      .catch((error) => {
        dispatch({
          type: MATERIAL.MATERIAL_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getMaterialsByCenter = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: MATERIAL.MATERIAL_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/material/center/${centerId}`,
    })
      .then((results) => {
        dispatch({
          type: MATERIAL.MATERIAL_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: MATERIAL.MATERIAL_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let createMaterial = (body) => {
  return async (dispatch) => {
    dispatch({
      type: MATERIAL.MATERIAL_CREATE,
    });
    try {
      const results = await instance.post("/material", body);
      dispatch({
        type: MATERIAL.MATERIAL_CREATE_SUCCESS,
        payload: results.data,
      });
    } catch (error) {
      dispatch({
        type: MATERIAL.MATERIAL_CREATE_FAILURE,
        payload: error,
      });
    }
  };
};

let editMaterial = (id, body) => {
  return async (dispatch) => {
    dispatch({
      type: MATERIAL.MATERIAL_EDIT,
    });
    try {
      const results = await instance.put(`/material/${id}`, body);
      dispatch({
        type: MATERIAL.MATERIAL_EDIT_SUCCESS,
        payload: results.data,
      });
      return results.data;
    } catch (error) {
      dispatch({
        type: MATERIAL.MATERIAL_EDIT_FAILURE,
        payload: error,
      });
      return false;
    }
  };
}

let deleteMaterial = (id) => {
  return async (dispatch) => {
    dispatch({
      type: MATERIAL.MATERIAL_DELETE,
    });
    try {
      const results = await instance.delete(`/material/${id}`);
      dispatch({
        type: MATERIAL.MATERIAL_DELETE_SUCCESS,
        payload: results.data,
      });
    } catch (error) {
      dispatch({
        type: MATERIAL.MATERIAL_DELETE_FAILURE,
        payload: error,
      });
    }
  };
}

export {
  createMaterial,
  getMaterials,
  getMaterialsByCenter,
  getMaterialsForOrder,
  editMaterial,
  deleteMaterial,
};
