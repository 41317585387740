import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import countries from "i18n-iso-countries";
import { store } from "../../store";
import { createSponsor } from "../../redux/actions/sponsor";
import * as React from "react";
import { showSuccessAlert } from "../ui/utils/AlertUtils";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryList = countries.getNames("en", { select: "official" });

export const ModalSponsor = ({ visible, onClose }) => {
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const isLoading = useSelector((state) => state.sponsor.isLoadingSponsor);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!name.trim()) newErrors.name = "Name is required";
    if (!address.trim()) newErrors.address = "Address is required";
    if (!city.trim()) newErrors.city = "City is required";
    if (!country.trim()) newErrors.country = "Country is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const created = await store.dispatch(createSponsor({name: name, address: address, city: city, country: country}));
      if (created) {
        showSuccessAlert("The sponsor was successfully created");
        // Reset form fields
        setName("");
        setAddress("");
        setCity("");
        setCountry("");
      }
      onClose(true);
    }
  };

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Create a sponsor
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Please indicate the following fields :
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                id="input-name"
                label="Name"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <FormControl fullWidth>
              <TextField
                id="input-address"
                label="Address"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                error={!!errors.address}
                helperText={errors.address}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <FormControl fullWidth>
              <TextField
                id="input-city"
                label="City"
                value={city}
                onChange={(event) => setCity(event.target.value)}
                error={!!errors.city}
                helperText={errors.city}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.country}>
              <InputLabel id="select-country">Country</InputLabel>
              <Select
                labelId="select-country"
                id="select-country"
                value={country}
                onChange={(event) => setCountry(event.target.value)}
                name="select-country"
                label="Country"
              >
                {Object.entries(countryList).map(([code, name]) => (
                  <MenuItem key={code} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.country}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, padding: 2 }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
