import Swal from 'sweetalert2';

// success alert
export function showSuccessAlert(text) {
    return Swal.fire({
        title: "Success",
        text: text,
        icon: "success",
        timer: 2000, 
        timerProgressBar: true, 
        confirmButtonText: "OK",
        confirmButtonColor: "#1e3179",
    });
}

// info alert
export function showInfoAlert(text) {
    return Swal.fire({
        text: text,
        icon: "info",
        timer: 2000, 
        timerProgressBar: true, 
        confirmButtonColor: "#1e3179",
    });
}

// warning alert
export function showWarningAlert(text) {
    return Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit",
        cancelButtonText: "No, cancel",
        confirmButtonColor: "#1e3179",
        reverseButtons: true,
    });
}

// error alert
export function showErrorAlert(text) {
    return Swal.fire({
        title: "Error!",
        text: text,
        icon: "error",
        confirmButtonColor: "#1e3179",
    });
}

