import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Button } from "@mui/material";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { store } from "../../../store";
import { changePassword } from "../../../redux/actions/user";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { showErrorAlert, showSuccessAlert } from "../../ui/utils/AlertUtils";
import usePasswordValidation from "../../ui/utils/ValidatePassword";

export const ScreenProfile = (props) => {
  const user = useSelector((state) => state.user.user);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { validatePassword, passwordError } = usePasswordValidation();
  const [showAlert, setShowAlert] = useState(false);
  const handlePasswordBlur = () => {
    validatePassword(newPassword);
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
    }

    try {
      const response = await store
        .dispatch(changePassword(user.id, newPassword))
        .catch((error) => {
          console.error("Error in dispatch: ", error);
        });

      if (response === true) {
        showSuccessAlert("Password changed successfully!");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        showErrorAlert("Password change failed");
      }
    } catch (error) {
      console.error("Error in handlePasswordChange: ", error);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
        }}
      >
        <div style={{ width: "100%" }}>
          <Box mx={4} mt={8}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  value={user.name || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  value={user.firstname || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  value={user.email || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Telephone"
                  value={user.phoneNumber || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
          <Box mx={4} mt={4} display="flex" justifyContent="center">
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                mb={1}
              >
                CHANGE YOUR PASSWORD:
              </Typography>
              <Typography variant="body2" mb={2}>
                Password must contain at least 8 characters, 1
                uppercase letter, 1 lowercase letter, and 1 symbol.
              </Typography>
              <form onSubmit={handlePasswordChange}>
                <Grid item mb={2}>
                  <TextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onBlur={handlePasswordBlur}
                    fullWidth
                  />
                  {passwordError && (
                    <Grid item mb={2}>
                      <Alert severity="error">{passwordError}</Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid item mb={2}>
                  <TextField
                    label="Confirm New Password"
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    fullWidth
                  />
                </Grid>
                {showAlert && (
                  <Grid item mb={2}>
                    <Alert severity="error">New passwords do not match!</Alert>
                  </Grid>
                )}
                <Grid item mb={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: "16px 32px" }}
                    fullWidth
                    type="submit"
                  >
                    Change Password
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Box>
        </div>
      </Paper>
    </Grid>
  );
};
