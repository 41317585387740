import { instance } from "../axios";

export const updateProductionStatus = async (
  productionId,
  status,
  centerId = null,
  shipmentId = null,
  comment = null
) => {
  const data = {
    status: status,
  };

  if (centerId) {
    data.centerId = centerId;
  }

  if (shipmentId) {
    data.shipmentId = shipmentId;
  }

  if (comment) {
    data.comment = comment;
  }

  await instance.put(`/production/${productionId}`, data);
};

export const fetchProductionsByOrderId = async (orderId) => {
  const response = await instance.get(`/production/order/${orderId}`);
  return response.data;
};

export const destroyProductions = async (selectedRows, destructionDate = new Date()) => {
  const response = await instance.put("/production/destroy", {
    ids: selectedRows,
    destructionDate: destructionDate.toISOString(),
  });
  return response.data;
};

export const updateProductionComment = async (productionId, comment) => {
  const response = await instance.put(`/production/${productionId}`, {
    comment: comment,
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Failed to update comment");
  }
};

export const attributeProduction = async (productionId, attributionDate, patientNumber) => {
  const response = await instance.put(`/production/${productionId}`, {
    status: "attributed to patient",
    attributionDate: attributionDate,
    patientNumber: patientNumber,
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Failed to attribute production");
  }
}