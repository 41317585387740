import React from "react";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";
import { Button, Grid } from "@mui/material";

const CSVDownloader = ({ data, filename, name }) => {
  const handleDownload = () => {
    const headers = Object.keys(data[0]);
    const aoaData = [headers];

    data.forEach((row) => {
      const rowData = [];
      headers.forEach((header) => {
        if (Object.prototype.toString.call(row[header]) === "[object Date]") {
          rowData.push({ v: row[header], t: "d" });
        } else {
          rowData.push(row[header]);
        }
      });
      aoaData.push(rowData);
    });

    const worksheet = utils.aoa_to_sheet(aoaData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const wbout = write(workbook, { bookType: "xlsx", type: "binary" });
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `${filename}.xlsx`
    );
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Button
        onClick={handleDownload}
        variant="contained"
        color="primary"
        size="large"
        style={{ padding: "16px 8px", margin: "16px" }}
        fullWidth
      >
        Download {name}
      </Button>
    </Grid>
  );
};

export default CSVDownloader;
