import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Fab from "@mui/material/Fab";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import { getUsers, updateUser } from "../../../redux/actions/user";
import { store } from "../../../store";
import { ModalUser } from "../../modals/User";
import { getStudies, getStudiesBySponsor } from "../../../redux/actions/study";
import { getCenters } from "../../../redux/actions/center";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { showSuccessAlert } from "../../ui/utils/AlertUtils";

const SPACING = 50;

export const ScreenUsers = (props) => {
  const users = useSelector((state) => state.user.users);
  const isLoading = useSelector((state) => state.user.isLoadingUsers);
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const studies = useSelector((state) => state.study.studies);
  const centers = useSelector((state) => state.center.centers);
  const [selectedStudies, setSelectedStudies] = React.useState([]);
  const [selectedCenters, setSelectedCenters] = React.useState([]);

  const columns = [
   { field: "name", headerName: "Name", flex: 1, renderCell: (params) => params.value.toUpperCase() },
    { field: "firstname", headerName: "First name", flex: 1 },
    { field: "organization", headerName: "Organization", flex: 1 },
{ field: "role", headerName: "Role", flex: 1, renderCell: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase() },
    { field: "email", headerName: "Mail", flex: 1.4 },
    { field: "phoneNumber", headerName: "Phone", flex: 0.8 },
  ];

  const handleStudyChange = (event, studyId) => {
    if (event.target.checked) {
      setSelectedStudies((prev) => [...prev, studyId]);
    } else {
      setSelectedStudies((prev) => prev.filter((id) => id !== studyId));
    }
  };

  const handleCenterChange = (event, centerId) => {
    if (event.target.checked) {
      setSelectedCenters((prev) => [...prev, centerId]);
    } else {
      setSelectedCenters((prev) => prev.filter((id) => id !== centerId));
    }
  };

  const handleConfirm = () => {
  let updates = {};

  if (selectedUser.role === "manager") {
    updates = { sponsorStudies: selectedStudies };
  } else if (selectedUser.role === "monitor") {
    updates = { centerMonitors: selectedCenters };
  } else if (selectedUser.organization === "CDL") {
    updates = { employeeStudies: selectedStudies };
  }

  store.dispatch(
    updateUser({
      id: selectedUser.id,
      updates,
    })
  );

  setVisibleDialog(false);
  showSuccessAlert("User updated successfully");
};

  // Set selected studies when user is selected
 React.useEffect(() => {
  if (selectedUser?.organization === 'Sponsor' && selectedUser?.sponsorStudies) {
    setSelectedStudies(selectedUser.sponsorStudies.map((study) => study.id));
  }
  if (selectedUser?.organization === 'CDL' && selectedUser?.employeeStudies) {
    setSelectedStudies(selectedUser.employeeStudies.map((study) => study.id));
  }
}, [selectedUser]);

  // Set selected centers when user is selected
  React.useEffect(() => {
    if (selectedUser?.centerMonitors) {
      setSelectedCenters(
        selectedUser.centerMonitors.map((center) => center.id)
      );
    }
  }, [selectedUser]);

  // Fetch users
  React.useEffect(() => {
    store.dispatch(getUsers());
  }, []);

  // Fetch studies and centers when dialog is opened
  React.useEffect(() => {
   if (selectedUser?.role === "manager") {
  store.dispatch(getStudiesBySponsor(selectedUser.sponsorId));
} else if (
  selectedUser?.role === "monitor" &&
  selectedUser.centerMonitors.length > 0
) {
  const studyId = selectedUser.centerMonitors[0].studyId; // retrieve studyId from center
  store.dispatch(getCenters(studyId));
} else if (
  selectedUser?.role === "employee" &&
  selectedUser.organization === "CDL"
) {
  store.dispatch(getStudies());
}
  }, [visibleDialog, selectedUser]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "90vh" }}>
          <DataGrid
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={users}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            rowHeight={30}
            pageSizeOptions={[50, 100]}
            checkboxSelection
            onCellDoubleClick={(params, event) => {
              if (
                params.field === "organization" &&
                (params.value === "Sponsor" ||
                  (params.value === "CDL" && params.row.role === "employee"))
              ) {
                setVisibleDialog(true);
                setSelectedUser(params.row);
              }
            }}
          />
        </div>
        <Dialog
          open={visibleDialog}
          onClose={() => setVisibleDialog(false)}
          PaperProps={{
            style: { padding: "20px" },
          }}
        >
          <DialogTitle>
            {selectedUser?.role === "monitor"
              ? "Select centers"
              : "Select studies"}{" "}
            to assign to {selectedUser?.firstname} {selectedUser?.name}{" "}
          </DialogTitle>
        { (selectedUser?.role === "manager" || selectedUser?.organization === "CDL") ? (
            // Form to add studies
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Studies</FormLabel>
              <FormGroup>
                {studies.map((study) => (
                  <FormControlLabel
                    key={study.id}
                    control={
                      <Checkbox
                        checked={selectedStudies.includes(study.id)}
                        onChange={(e) => handleStudyChange(e, study.id)}
                        name={study.cdlStudyCode}
                      />
                    }
                    label={study.cdlStudyCode}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ) : selectedUser?.role === "monitor" ? (
            // Form to add centers
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Centers</FormLabel>
              <FormGroup>
                {centers.map((center) => (
                  <FormControlLabel
                    key={center.id}
                    control={
                      <Checkbox
                        checked={selectedCenters.includes(center.id)}
                        onChange={(e) => handleCenterChange(e, center.id)}
                        name={center.centerNumber}
                      />
                    }
                    label={center.centerNumber}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ) : null}
          <DialogActions>
            <Button onClick={() => setVisibleDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setVisibleCreateEdit(true)}
        style={{ position: "absolute", right: SPACING, bottom: SPACING }}
      >
        <AddIcon />
      </Fab>
      <ModalUser
        visible={visibleCreateEdit}
        onClose={(refresh) => {
          setVisibleCreateEdit(false);
          if (refresh) {
            store.dispatch(getUsers());
          }
        }}
      ></ModalUser>
    </Grid>
  );
};
