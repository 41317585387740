import React from "react";
import {
  Modal,
  Box,
  Grid,
  FormControl,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { store } from "../../store";
import { showErrorAlert, showSuccessAlert } from "../ui/utils/AlertUtils";
import { createUser } from "../../redux/actions/user";
import { getStudies, getStudiesBySponsor } from "../../redux/actions/study";
import { getCenters } from "../../redux/actions/center";
import { getSponsors } from "../../redux/actions/sponsor";
import { generatePassword } from "../ui/utils/PasswordGenerator";

export const ModalUser = ({ visible, onClose }) => {
  const [name, setName] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const studies = useSelector((state) => state.study.studies);
  const centers = useSelector((state) => state.center.centers);
  const sponsors = useSelector((state) => state.sponsor.sponsors);
  const [centerId, setCenterId] = React.useState("");
  const [studyId, setStudyId] = React.useState("");
  const [checkedStudies, setCheckedStudies] = React.useState([]);
  const [sponsorId, setSponsorId] = React.useState("");
  const [checkedCenters, setCheckedCenters] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [password, setPassword] = React.useState(generatePassword());
  const [showPassword, setShowPassword] = React.useState(false);
  const isLoading = useSelector((state) => state.user.isLoadingUser);

  const validateForm = () => {
    const newErrors = {};

    if (!name.trim()) newErrors.name = "Name is required";
    else if (/[\d!@#$%^&*(),.?":{}|<>]/g.test(name))
      newErrors.name = "Name can't contain digits or special characters";

    if (!firstname.trim()) newErrors.firstname = "First name is required";
    else if (/[\d!@#$%^&*(),.?":{}|<>]/g.test(firstname))
      newErrors.firstname =
        "First name can't contain digits or special characters";

    if (!email.trim()) newErrors.email = "Email is required";
    if (!password.trim()) newErrors.password = "Password is required";
    if (!role.trim()) newErrors.role = "Role is required";
    if (!organization.trim())
      newErrors.organization = "Organization is required";
    if (!phoneNumber.trim()) newErrors.phoneNumber = "Phone number is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();

    if (!isValid) {
    } else {
      try {
        const created = await store.dispatch(
          createUser({
            name: name,
            firstname: firstname,
            email: email,
            password: password,
            role: role,
            organization: organization,
            phoneNumber: phoneNumber,
            centerId: centerId === "" ? null : centerId,
            sponsorId: sponsorId === "" ? null : sponsorId,
            checkedCenters: checkedCenters,
            checkedStudies:
              checkedCenters.length > 0
                ? [...checkedStudies, studyId]
                : checkedStudies,
          })
        );
        if (created) {
          showSuccessAlert("The user was successfully created");
          // Reset form fields
          setName("");
          setFirstname("");
          setEmail("");
          setPassword("");
          setRole("");
          setOrganization("");
          setPhoneNumber("");
          setSponsorId("");
          setStudyId("");
          setCenterId("");
          setCheckedCenters([]);
          setCheckedStudies([]);
        }
        onClose(true);
      } catch (error) {
        console.error(error);
        showErrorAlert("An error occurred while creating the user");
      }
    }
  };

  // Email validation regex
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleEmailChange = (event) => {
    setEmail(event.target.value);

    // Validate email
    if (!emailRegex.test(event.target.value)) {
      setErrors({ ...errors, email: "Invalid email address" });
    } else {
      setErrors({ ...errors, email: "" });
    }
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);

    // Validate phone number
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
    if (!phoneRegex.test(event.target.value)) {
      setErrors({
        ...errors,
        phoneNumber: "Invalid phone number, enter digits only",
      });
    } else {
      setErrors({ ...errors, phoneNumber: "" });
    }
  };

  // fetch a center for center User
  React.useEffect(() => {
    if (studyId) {
      store.dispatch(getCenters(studyId));
    }
  }, [studyId]);

  // fetch all sponsors for sponsor User
  React.useEffect(() => {
    if (organization === "Sponsor") {
      store.dispatch(getSponsors());
    }
  }, [organization]);

  // fetch all studies for sponsor
  React.useEffect(() => {
    if (sponsorId) {
      store.dispatch(getStudiesBySponsor(sponsorId));
    } else {
      store.dispatch(getStudies());
    }
  }, [sponsorId]);

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Create a new user
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value.toUpperCase());
                }}
                id="input-name"
                label="Name"
                error={!!errors.name}
                helperText={errors.name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-firstname"
                value={firstname}
                onChange={(event) => {
                  setFirstname(event.target.value);
                }}
                id="input-firstname"
                label="First Name"
                error={!!errors.firstname}
                helperText={errors.firstname}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-email"
                value={email}
                onChange={handleEmailChange}
                id="input-email"
                label="Email"
                error={!!errors.email}
                helperText={errors.email}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                name="input-phoneNumber"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                id="input-phoneNumber"
                label="Phone Number"
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                autoComplete="new-password"
                required
                name="input-password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                id="input-password"
                label="Password"
                type={showPassword ? "text" : "password"}
                error={!!errors.password}
                helperText={errors.password}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword}
                    onChange={(event) => setShowPassword(event.target.checked)}
                    name="show-password"
                  />
                }
                label="Show password"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setPassword(generatePassword())}
            >
              Generate Password
            </Button>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.organization}>
              <InputLabel id="organization-select-label">
                Organization
              </InputLabel>
              <Select
                required
                name="input-organization"
                value={organization}
                onChange={(event) => {
                  setOrganization(event.target.value);
                  setRole("");
                  setStudyId("");
                  setCheckedCenters([]);
                  setCheckedStudies([]);
                  setCenterId("");
                  setSponsorId("");
                }}
                id="input-organization"
                label="Organization"
              >
                <MenuItem value="CDL">CDL</MenuItem>
                <MenuItem value="Sponsor">Sponsor</MenuItem>
                <MenuItem value="Center">Center</MenuItem>
              </Select>
              {errors.organization && (
                <FormHelperText>{errors.organization}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.role}>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                required
                name="input-role"
                value={role}
                onChange={(event) => {
                  setRole(event.target.value);
                }}
                id="input-role"
                label="Role"
              >
                {organization === "CDL"
                  ? [
                      <MenuItem key="admin" value="admin">
                        Admin
                      </MenuItem>,
                      <MenuItem key="technician" value="technician">
                        Technician
                      </MenuItem>,
                      <MenuItem key="employee" value="employee">
                        Employee
                      </MenuItem>,
                    ]
                  : organization === "Center"
                  ? [
                      <MenuItem key="manager" value="manager">
                        Manager
                      </MenuItem>,
                      <MenuItem key="employee" value="employee">
                        Employee
                      </MenuItem>,
                    ]
                  : [
                      <MenuItem key="manager" value="manager">
                        Manager
                      </MenuItem>,
                      <MenuItem key="monitor" value="monitor">
                        Monitor
                      </MenuItem>,
                    ]}
              </Select>
              {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
            </FormControl>
          </Grid>
          {organization === "Center" && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="study-select-label">Study</InputLabel>
                  <Select
                    required
                    name="input-studyId"
                    value={studyId}
                    onChange={(event) => {
                      const selectedStudy = studies.find(
                        (study) => study.id === event.target.value
                      );
                      setStudyId(selectedStudy.id);
                      setCenterId("");
                    }}
                    id="input-studyId"
                    label="Study"
                  >
                    {studies.map((study) => (
                      <MenuItem key={study.id} value={study.id}>
                        {study.cdlStudyCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {studyId && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    {centers.length > 0 ? (
                      <>
                        <InputLabel id="center-select-label">
                          Center Number
                        </InputLabel>
                        <Select
                          required
                          name="input-centerId"
                          value={centerId}
                          onChange={(event) => {
                            const selectedCenter = centers.find(
                              (center) => center.id === event.target.value
                            );
                            setCenterId(selectedCenter.id);
                          }}
                          id="input-centerId"
                          label="Center Number"
                        >
                          {centers.map((center) => (
                            <MenuItem key={center.id} value={center.id}>
                              {center.centerNumber}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <Box mt={2}>
                        <Typography variant="body1">
                          No centers for this study
                        </Typography>
                      </Box>
                    )}
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          {organization === "Sponsor" && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="sponsor-select-label">Sponsor</InputLabel>
                <Select
                  required
                  name="input-sponsorId"
                  value={sponsorId}
                  onChange={(event) => {
                    setSponsorId(event.target.value);
                    setStudyId("");
                    setCheckedCenters([]);
                  }}
                  id="input-sponsorId"
                  label="Sponsor"
                >
                  {sponsors.map((sponsor) => (
                    <MenuItem key={sponsor.id} value={sponsor.id}>
                      {sponsor.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {((role === "manager" && sponsorId) ||
            (organization === "CDL" && role === "employee")) && (
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Studies</FormLabel>
                <FormGroup row wrap="wrap">
                  {studies && studies.length > 0 ? (
                    studies.map((study) => (
                      <FormControlLabel
                        key={study.id}
                        control={
                          <Checkbox
                            checked={checkedStudies.includes(study.id)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setCheckedStudies([
                                  ...checkedStudies,
                                  study.id,
                                ]);
                              } else {
                                setCheckedStudies(
                                  checkedStudies.filter((id) => id !== study.id)
                                );
                              }
                            }}
                          />
                        }
                        label={study.cdlStudyCode}
                      />
                    ))
                  ) : (
                    <FormHelperText>No studies</FormHelperText>
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
          )}

          {role === "monitor" && sponsorId && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="study-label">Study</InputLabel>
                  <Select
                    labelId="study-label"
                    id="study-select"
                    value={studyId}
                    onChange={(event) => {
                      setStudyId(event.target.value);
                      setCheckedCenters([]);
                    }}
                    label="Study"
                  >
                    {studies &&
                      studies.map((study) => (
                        <MenuItem key={study.id} value={study.id}>
                          {study.cdlStudyCode}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {studyId && (
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Centers</FormLabel>
                    <FormGroup
                      style={{ flexDirection: "row", flexWrap: "wrap" }}
                    >
                      {centers.map((center) => (
                        <FormControlLabel
                          key={center.id}
                          control={
                            <Checkbox
                              checked={checkedCenters.includes(center.id)}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setCheckedCenters([
                                    ...checkedCenters,
                                    center.id,
                                  ]);
                                } else {
                                  setCheckedCenters(
                                    checkedCenters.filter(
                                      (id) => id !== center.id
                                    )
                                  );
                                }
                              }}
                              name={center.centerNumber}
                            />
                          }
                          label={center.centerNumber}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, padding: 2 }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
