import { useState } from 'react';

const usePasswordValidation = () => {
  const [passwordError, setPasswordError] = useState(null);

  const validatePassword = (password) => {
    // At least 8 characters
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
      return false;
    }

    // At least one lowercase letter
    if (!/[a-z]/.test(password)) {
      setPasswordError("Password must contain at least one lowercase letter");
      return false;
    }

    // At least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      setPasswordError("Password must contain at least one uppercase letter");
      return false;
    }

    // At least one symbol
  if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]+/.test(password)) {
      setPasswordError("Password must contain at least one symbol");
      return false;
    }

    setPasswordError(null);
    return true;
  };

  return { validatePassword, passwordError };
};

export default usePasswordValidation;