import { Grid, Paper, Box } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStudyInfo } from "../../../redux/actions/study";
import { store } from "../../../store";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { Button } from "@mui/material";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  addCenterToProtocol,
  createProtocol,
  deleteProtocol,
  getCentersForProtocol,
  removeCenterFromProtocol,
  updateProtocolStartDate,
} from "../../../services/protocolService";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Swal from "sweetalert2";
import {
  showErrorAlert,
  showInfoAlert,
  showSuccessAlert,
} from "../../ui/utils/AlertUtils";

export const ScreenStudyInfo = (props) => {
  const location = useLocation();
  const studyId = location.state.studyId;
  const study = useSelector((state) => state.study.study);
  const isLoading = useSelector((state) => state.study.isLoading);
  const sponsorManagers = study?.sponsors?.filter(
    (user) => user.role !== "monitor"
  );
  const protocols = study?.protocols;
  const centers = useSelector((state) => state.center.centers);
  const [centersDialogOpen, setCentersDialogOpen] = React.useState(false);
  const [dialog, setDialog] = React.useState({
    open: false,
    date: null,
    protocolId: null,
  });
  const [selectedCenters, setSelectedCenters] = React.useState([]);
  const [initiallySelectedCenters, setInitiallySelectedCenters] =
    React.useState([]);
  const [removedCenters, setRemovedCenters] = React.useState([]);
  const [currentProtocolId, setCurrentProtocolId] = React.useState(null);
  const [selectedProtocol, setSelectedProtocol] = React.useState(null);
  const [, setAddedCenters] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [reference, setReference] = React.useState("");
  const [protocolChanged, setProtocolChanged] = React.useState(false);
  const monitors = study?.Centers
    ? study.Centers.reduce((acc, center) => {
        const newAcc = [...acc];
        center.monitors.forEach((monitor) => {
          const existingMonitor = newAcc.find((m) => m.id === monitor.id);
          if (existingMonitor) {
            existingMonitor.centers.push({
              id: center.id,
              centerNumber: center.centerNumber,
            });
          } else {
            newAcc.push({
              id: monitor.id,
              name: monitor.name,
              firstname: monitor.firstname,
              email: monitor.email,
              phoneNumber: monitor.phoneNumber,
              centers: [{ id: center.id, centerNumber: center.centerNumber }],
            });
          }
        });
        return newAcc;
      }, [])
    : [];

  const handleConfirmDate = async () => {
    try {
      await updateProtocolStartDate(dialog.protocolId, dialog.date);
      showSuccessAlert("Protocol start date updated successfully");
      setProtocolChanged(true);
      setDialog({ open: false, date: null, protocolId: null });
    } catch (error) {
      console.error(
        "An error occurred while updating the study start date:",
        error
      );
      showErrorAlert("Failed to update protocol start date");
    }
  };

  const handleNewProtocol = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateProtocol = async (event) => {
    event.preventDefault();
    setOpen(false);
    const body = { studyId, startDate, reference };
    try {
      const response = await createProtocol(body);
      if (response) {
        setProtocolChanged(true);
        showSuccessAlert("Protocol successfully created");
      }
    } catch (error) {
      showErrorAlert("Failed to create protocol");
    }
  };

  const handleCentersDialogOpen = async (protocolId) => {
    try {
      const linkedCenters = await getCentersForProtocol(protocolId);
      const linkedCenterIds = linkedCenters.map((center) =>
        center.id.toString()
      );
      setSelectedCenters(linkedCenterIds);
      setInitiallySelectedCenters(linkedCenterIds);
    } catch (error) {
      console.error("Failed to fetch centers for protocol", error);
    }
    setCentersDialogOpen(true);
  };

  const handleCentersDialogClose = () => {
    setCentersDialogOpen(false);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedCenters([...selectedCenters, event.target.name]);
    } else {
      setSelectedCenters(
        selectedCenters.filter((centerId) => centerId !== event.target.name)
      );
      if (initiallySelectedCenters.includes(event.target.name)) {
        setRemovedCenters((prevRemovedCenters) => [
          ...prevRemovedCenters,
          event.target.name,
        ]);
      }
    }
  };

  const handleConfirm = async () => {
    if (selectedCenters.length === 0 && removedCenters.length === 0) {
      handleCentersDialogClose();
      showInfoAlert("No changes made");
    } else {
      for (const centerId of selectedCenters) {
        if (!initiallySelectedCenters.includes(centerId)) {
          try {
            const response = await addCenterToProtocol(
              currentProtocolId,
              centerId
            );
            if (response) {
              setProtocolChanged(true);
              // Update the addedCenters state
              setAddedCenters((prevAddedCenters) => [
                ...prevAddedCenters,
                centerId,
              ]);
            }
          } catch (error) {
            showErrorAlert("Failed to add center to protocol");
          }
        }
      }
      for (const centerId of removedCenters) {
        removeCenterFromProtocol(currentProtocolId, centerId);
      }
      if (selectedCenters.length > 0 || removedCenters.length > 0) {
        showSuccessAlert("Changes saved successfully");
      }
      setSelectedCenters([]); // Clear selectedCenters
      setRemovedCenters([]); // Clear removedCenters
    }
    handleCentersDialogClose();
  };

  // Update the protocol start date
  const handleCellEditCommit = async (params) => {
    if (params.field === "startDate") {
      const newDate = params.value;
      const protocolId = params.id;

      try {
        await updateProtocolStartDate(protocolId, newDate);
        showSuccessAlert("Protocol start date updated successfully");
      } catch (error) {
        console.error(
          "An error occurred while updating the study start date:",
          error
        );
        showErrorAlert("Failed to update protocol start date");
      }
    }
  };

  // cell to add Centers to protocol
  const renderEditCell = (params) => {
    const onClick = () => {
      const protocolId = params.row.id;
      setCurrentProtocolId(protocolId);
      handleCentersDialogOpen(protocolId);

      const selectedProtocol = protocols.find(
        (protocol) => protocol.id === protocolId
      );
      setSelectedProtocol(selectedProtocol); // Update the state
    };

    return (
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onClick}
        >
          Manage Centers
        </Button>
      </Box>
    );
  };
  //sponsorManagers
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "firstname", headerName: "First name", flex: 1 },
    { field: "email", headerName: "Mail", flex: 1 },
    { field: "phoneNumber", headerName: "Phone", flex: 1 },
  ];

  const monitorColumns = [
    ...columns,
    {
      field: "centers",
      headerName: "Centers",
      flex: 1,
      valueGetter: (params) =>
        params.row.centers.map((center) => center.centerNumber).join(", "),
    },
  ];

  const protocolColumns = [
    { field: "reference", headerName: "Reference", flex: 0.4 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 0.4,
      renderCell: (params) => (
        <div
          onDoubleClick={() =>
            setDialog({ open: true, date: params.value, protocolId: params.id })
          }
        >
          {dayjs(params.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "",
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: renderEditCell,
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = async () => {
          const id = params.row.id;
          Swal.fire({
            title: "Are you sure?",
            text: "You are about to delete the protocol. You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1e3179",
            confirmButtonText: "Yes, delete it!",
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                const protocolDeleted = await deleteProtocol(id);
                if (protocolDeleted) {
                  showSuccessAlert("Protocol deleted successfully");
                  setProtocolChanged(true);
                }
              } catch (error) {
                showErrorAlert("Failed to delete protocol");
                console.error("Failed to delete protocol", error);
              }
            }
          });
        };

        return (
          <Button onClick={onClick} color="secondary" variant="contained">
            Delete
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    if (protocolChanged) {
      setProtocolChanged(false);
    }
    store.dispatch(getStudyInfo(studyId));
  }, [studyId, protocolChanged]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
      >
        <div style={{ width: "100%" }}>
          <Box mx={4} mt={8}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="CDL Study Code"
                  value={study?.cdlStudyCode || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Sponsor Study Code"
                  value={study?.sponsorStudyCode || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Sponsor"
                  value={study?.sponsor?.name || ""}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={4}>
            <TextField
              value="SPONSOR MANAGERS"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {!isLoading && sponsorManagers && sponsorManagers?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={sponsorManagers}
                columns={columns}
                loading={isLoading}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20]}
                checkboxSelection
                hideFooter
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                There are no sponsor managers for this study yet
              </div>
            )}
          </Box>
          <Box m={4}>
            <TextField
              value="MONITORS"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {!isLoading && monitors && monitors?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={monitors}
                columns={monitorColumns}
                loading={isLoading}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20]}
                checkboxSelection
                hideFooter
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                There are no monitors for this study yet
              </div>
            )}
          </Box>

          <Box m={4}>
            <TextField
              value="PROTOCOLS"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            {!isLoading && protocols && protocols?.length > 0 ? (
              <DataGrid
                disableRowSelectionOnClick
                slots={{
                  noRowsOverlay: NoRowOverlay,
                  loadingOverlay: LinearProgress,
                }}
                rows={protocols}
                columns={protocolColumns}
                loading={isLoading}
                pageSizeOptions={[10, 20]}
                hideFooter
                onCellEditCommit={handleCellEditCommit}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                There are no additional protocols for this study yet
              </div>
            )}
            {/* EDIT THE START DATE OF A PROTOCOL */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <Dialog
                PaperProps={{
                  style: { padding: "20px" },
                }}
                open={dialog.open}
                onClose={() =>
                  setDialog({ open: false, date: null, protocolId: null })
                }
              >
                <DatePicker
                  value={dayjs(dialog.date)}
                  onChange={(newValue) => {
                    const dateStr = newValue.format("YYYY-MM-DD");
                    setDialog({ ...dialog, date: new Date(dateStr) });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleConfirmDate}
                >
                  Confirm
                </Button>
              </Dialog>
            </LocalizationProvider>

            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleNewProtocol}
                style={{ padding: "16px 88px", marginTop: "16px" }}
              >
                Add a new protocol
              </Button>
              {/* ADD A NEW PROTOCOL */}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                  style: { padding: "20px" },
                }}
              >
                <DialogTitle id="form-dialog-title">New Protocol</DialogTitle>
                <DialogContent>
                  <Box mb={2}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="reference"
                      label="Reference"
                      type="text"
                      fullWidth
                      value={reference}
                      onChange={(event) => setReference(event.target.value)}
                    />
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      label="Start Date"
                      name="start_date"
                      value={dayjs(startDate)}
                      onChange={(newValue) => {
                        setStartDate(newValue.format("YYYY-MM-DD"));
                      }}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleCreateProtocol}
                    color="primary"
                    variant="contained"
                  >
                    Create
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            {/* ADD CENTERS TO A PROTOCOL  */}
            <Dialog
          open={centersDialogOpen}
          onClose={handleCentersDialogClose}
          aria-labelledby="add-centers-dialog-title"
          aria-describedby="add-centers-dialog-description"
          BackdropProps={{
            style: {
              backgroundColor: "rgba(30, 49, 121, 0.8)",
            },
          }}
          PaperProps={{
            style: { width: "80%", padding: "15px" },
          }}
        >
          <DialogTitle id="add-centers-dialog-title">
            {`Add or remove centers for protocol ${
              selectedProtocol ? selectedProtocol.reference : ""
            }`}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              {centers.map((center) => (
                <Grid item xs={12} sm={6} key={center.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxChange}
                        name={center.id.toString()}
                        checked={selectedCenters.includes(center.id.toString())}
                      />
                    }
                    label={center.centerNumber}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCentersDialogClose}
              color="primary"
              variant="contained"
              size="large"
              style={{ padding: "16px 88px", margin: "16px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              size="large"
              style={{
                padding: "16px 88px",
                margin: "16px",
                color: "#fff",
                backgroundColor: "#1e3179",
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
          </Box>
        </div>
      </Paper>
    </Grid>
  );
};
