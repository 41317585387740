import React from "react";
import { Grid, TextField } from "@mui/material";

export const CustomTextField = ({
  name,
  readOnly = false,
  value,
  onChange,
  ...props
}) => {
  const label = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        id={name}
        name={name}
        label={label}
        autoComplete={name}
        InputProps={{
          readOnly: readOnly,
        }}
        inputProps={{
          style: { height: "15px" }, // Set the height of the input element
        }}
        InputLabelProps={props.InputLabelProps} 
        value={value}
        onChange={onChange}
        {...props}
      />
    </Grid>
  );
};
