import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Fab from "@mui/material/Fab";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import { ModalSponsor } from "../../modals/Sponsor";
import { store } from "../../../store";
import { getSponsors, updateSponsor } from "../../../redux/actions/sponsor";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { showSuccessAlert } from "../../ui/utils/AlertUtils";

const SPACING = 50;

export const ScreenSponsors = (props) => {
  const sponsors = useSelector((state) => state.sponsor.sponsors);
  const isLoading = useSelector((state) => state.sponsor.isLoadingSponsors);
  const user = useSelector((state) => state.user.user);
  const admin = user.role === "admin";
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedSponsor, setSelectedSponsor] = React.useState(null);
  const updatedSponsor = useSelector((state) => state.sponsor.updatedSponsor);

  const handleCellDoubleClick = (params) => {
    if (params.field === 'name' && admin)  {
      setSelectedSponsor(params.row);
      setOpenDialog(true);
    }
  };

  const handleSave = async () => {
    setOpenDialog(false);
    await store.dispatch(updateSponsor(selectedSponsor));
    showSuccessAlert('Sponsor updated successfully');
  };

  React.useEffect(() => {
    store.dispatch(getSponsors());
  }, [updatedSponsor]);

  const columns = [
    { field: "name", headerName: "Name", flex: 0.2 },
    { field: "address", headerName: "Address", flex: 0.3 },
    { field: "city", headerName: "City", flex: 0.2 },
    { field: "country", headerName: "Country", flex: 0.2 },
  ];

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "85vh" }}>
          <DataGrid
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={sponsors}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
            rowHeight={40}
            checkboxSelection
            onCellDoubleClick={handleCellDoubleClick}
          />
        </div>
        <Dialog PaperProps={{
              style: { padding: "20px" },
            }} open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Sponsor</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={selectedSponsor?.name || ''}
            onChange={(e) => setSelectedSponsor({ ...selectedSponsor, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Address"
            type="text"
            fullWidth
            value={selectedSponsor?.address || ''}
            onChange={(e) => setSelectedSponsor({ ...selectedSponsor, address: e.target.value })}
          />
          <TextField
            margin="dense"
            label="City"
            type="text"
            fullWidth
            value={selectedSponsor?.city || ''}
            onChange={(e) => setSelectedSponsor({ ...selectedSponsor, city: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Country"
            type="text"
            fullWidth
            value={selectedSponsor?.country || ''}
            onChange={(e) => setSelectedSponsor({ ...selectedSponsor, country: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          setVisibleCreateEdit(true);
        }}
        style={{ position: "absolute", right: SPACING, bottom: SPACING }}
      >
        <AddIcon />
      </Fab>
      <ModalSponsor
        visible={visibleCreateEdit}
        onClose={(refresh) => {
          setVisibleCreateEdit(false);
          if (refresh) {
            store.dispatch(getSponsors());
          }
        }}
      ></ModalSponsor>
    </Grid>
  );
};
