import { PRODUCTION } from "../constants";
import { instance } from "../../axios";

let getProductions = (studyId) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/production/study/${studyId}`,
    })
      .then((results) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getProductionsByCenter = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_GET_ALL,
    });
    return instance({
      method: "GET",
      url: `/production/center/${centerId}`,
    })
      .then((results) => {
        // Sort by expiration date
        let sortedProductions = results.data.sort(
          (a, b) => new Date(a.expirationDate) - new Date(b.expirationDate)
        );
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_SUCCESS,
          payload: sortedProductions,
        });
        return sortedProductions; // Return the data
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getExpiringProductions = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/production/center/${centerId}/expiring`,
    })
      .then((results) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

// get productions available for an order
let getProductionsByMaterial = (materialId) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_GET_ALL,
    });
    return instance({
      method: "GET",
      url: `/production/produced/material/${materialId}`,
    })
      .then((results) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_SUCCESS,
          payload: results.data,
        });
        return results.data; // return the fetched productions
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_FAILURE,
          payload: error,
        });
        throw error; // propagate the error
      });
  };
};

let getProductionsByShipment = (shipmentId) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/production/shipment/${shipmentId}`,
    })
      .then((results) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
}

let createProduction = (body) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_CREATE,
    });
    return instance
      .post("/production", body)
      .then((results) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_CREATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_CREATE_FAILURE,
          payload: error,
        });
        throw error; 
      });
  };
};

let updateProduction = (id, body) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_UPDATE,
    });
    return instance
      .put("/production/" + id, body)
      .then((results) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_UPDATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_UPDATE_FAILURE,
          payload: error,
        });
        throw error; 
      });
  };
}

let deleteProduction = (id) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTION.PRODUCTION_DELETE,
    });
    return instance
      .delete("/production/" + id)
      .then((results) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_DELETE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PRODUCTION.PRODUCTION_DELETE_FAILURE,
          payload: error,
        });
        throw error; 
      });
  };
}

export {
  createProduction,
  getProductions,
  getProductionsByCenter,
  getProductionsByMaterial,
  getExpiringProductions,
  getProductionsByShipment,
  updateProduction,
  deleteProduction
};
