import StudiesIcon from "@mui/icons-material/QueryStats";
import KitsIcon from "@mui/icons-material/Science";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CentersIcon from "@mui/icons-material/Store";
import SponsorsIcon from "@mui/icons-material/Apartment";
import UsersIcon from "@mui/icons-material/People";
import ProductionsIcon from "@mui/icons-material/Biotech";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const renderTitle = (location, studyCode, centerNumber, centerUser) => {
  let title;

  switch (location.pathname) {
    case "/":
      title = centerUser ? 
        <div style={{ display: "flex", alignItems: "center" }}><ProductionsIcon key="icon" /> Inventory</div> : <div style={{ display: "flex", alignItems: "center" }}><StudiesIcon key="icon" style={{ marginRight: '8px' }}/> Studies</div>;
      break;
    case "/legal":
      title = "Legal Notices & Privacy Policy"; 
      break;
    case "/centers":
      title = [<CentersIcon key="icon"/>, " Centers"];
      break;
    case "/users":
      title = <div style={{ display: "flex", alignItems: "center" }}><UsersIcon key="icon" style={{ marginRight: '8px' }}/> Users</div>;
      break;
    case "/studies":
      title = <div style={{ display: "flex", alignItems: "center" }}><StudiesIcon key="icon" style={{ marginRight: '8px' }}/> Studies </div>;
      break;
    case "/study-info":
      title = <div style={{ display: "flex", alignItems: "center" }}><StudiesIcon key="icon" style={{ marginRight: '8px' }}/> Study Information </div>;
      break;
    case "/materials":
      title = [<KitsIcon key="icon" style={{ marginRight: '4px' }}/>, " Material List"];
      break;
    case "/orders":
      title = <div style={{ display: "flex", alignItems: "center" }}><ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/> Orders</div>;
      break;
    case "/orders/":
      title = [<ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/>, " Orders"];
      break;
    case "/orders/new":
      title = [<ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/>, " Create an order"];
      break;
    case "/orders/confirm":
      title = [<ShoppingCartIcon key="icon" style={{ marginRight: '4px' }}/>, " Confirm your order"];
      break;
    case "/shipping":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, "Shipping information"];
      break;
    case "/shipping/new":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, "Provide shipping information"];
      break;
    case "/shipping/confirm":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, "Confirm shipping information"];
      break;
    case "/aor":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, "AOR"];
      break;
    case "/aor-form":
      title = [<LocalShippingIcon key="icon" style={{ marginRight: '4px' }}/>, "Confirm reception"];
      break;
    case "/productions":
      title = [
        <ProductionsIcon key="icon" />,
        centerNumber || centerUser ? " Inventory" : " Productions",
      ];
      break;
    case "/sponsors":
      title = <div style={{ display: "flex", alignItems: "center" }}><SponsorsIcon key="icon" style={{ marginRight: '8px' }}/> Sponsors</div>;
      break;
    case "/center-info":
      title = "";
      break;
    case "/order":
      title = [<ShoppingCartIcon key="icon" />, " Order Information"];
      break;
    case "/profile":
  title = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <UsersIcon key="icon" style={{ marginRight: '8px' }}/>
      {"My profile"}
    </div>
  );
      break;
    default:
      title = "Dashboard";
  }

  // If a center is selected, display studyCode + centerNumber + title
  if (centerNumber) {
    title = (
      <div style={{ display: "flex", alignItems: "center" }}>
        {studyCode && <>
          <StudiesIcon style={{ marginRight: '8px' }} />
          <span style={{ marginRight: '20px' }}>{studyCode}</span>
        </>}
        <CentersIcon style={{ marginRight: '4px' }}/>
        <span style={{ marginRight: '20px' }}> {`Center ${centerNumber} `}</span>
        {title}
      </div>
    );
}
  // If only a study is selected, display studyCode + title
  else if (studyCode) {
    title = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <StudiesIcon style={{ marginRight: '8px' }}/>
        <span style={{ marginRight: '20px' }}>{` ${studyCode} `}</span>
        {title}
      </div>
    );
  }
  return title;
};

export default renderTitle;
