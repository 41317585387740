import { PRODUCTION } from "../constants";

const initialState = {
  productions: [],
  production: null,
  isLoadingProductions: false,
  isLoadingProduction: false,
  createdProduction: false,
  updatedProduction: false,
  deletedProduction: false,
  error: null,
};

const productionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PRODUCTION.PRODUCTION_GET_ALL:
      return {
        ...state,
        tokenAccess: null,
        isLoadingProductions: true,
        createdProduction: false,
      };
    case PRODUCTION.PRODUCTION_GET_ALL_FAILURE:
      return {
        ...state,
        isLoadingProductions: false,
        createdProduction: false,
        error: action.payload,
      };
    case PRODUCTION.PRODUCTION_GET_ALL_SUCCESS:
      return {
        ...state,
        isLoadingProductions: false,
        productions: action.payload,
        createdProduction: false,
        error: null,
      };
    case PRODUCTION.PRODUCTION_CREATE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingProduction: true,
        createdProduction: false,
      };
    case PRODUCTION.PRODUCTION_CREATE_FAILURE:
      return {
        ...state,
        isLoadingProduction: false,
        createdProduction: false,
        error: action.payload,
      };
    case PRODUCTION.PRODUCTION_CREATE_SUCCESS:
      return {
        ...state,
        isLoadingProduction: false,
        production: action.payload,
        createdProduction: true,
        error: null,
      };
    case PRODUCTION.PRODUCTION_UPDATE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingProduction: true,
        updatedProduction: false,
      };
    case PRODUCTION.PRODUCTION_UPDATE_FAILURE:
      return {
        ...state,
        isLoadingProduction: false,
        updatedProduction: false,
        error: action.payload,
      };
    case PRODUCTION.PRODUCTION_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingProduction: false,
        production: action.payload,
        updatedProduction: true,
        error: null,
      };
    case PRODUCTION.PRODUCTION_DELETE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingProduction: true,
        deletedProduction: false,
      };
    case PRODUCTION.PRODUCTION_DELETE_FAILURE:
      return {
        ...state,
        isLoadingProduction: false,
        deletedProduction: false,
        error: action.payload,
      };
    case PRODUCTION.PRODUCTION_DELETE_SUCCESS:
      return {
        ...state,
        isLoadingProduction: false,
        production: action.payload,
        deletedProduction: true,
        error: null,
      };

    default:
      return state;
  }
};

export default productionReducer;
