import { SPONSOR } from "../constants";

const initialState = {
  sponsor: null,
  sponsors: [],
  isLoadingSponsors: false,
  createdSponsor: false,
  updatedSponsor: false,
  isLoadingSponsor: false,
  error: null,
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SPONSOR.SPONSOR_GET_ALL:
      return {
        ...state,
        tokenAccess: null,
        isLoadingSponsors: true,
        createdSponsor: false,
        updatedSponsor: false,
      };
    case SPONSOR.SPONSOR_GET_ALL_FAILURE:
      return {
        ...state,
        isLoadingSponsors: false,
        createdSponsor: false,
        error: action.payload,
      };
    case SPONSOR.SPONSOR_GET_ALL_SUCCESS:
      return {
        ...state,
        isLoadingSponsors: false,
        sponsors: action.payload,
        createdSponsor: false,
        error: null,
      };
    case SPONSOR.SPONSOR_UPDATE:
      return {
        ...state,
        isLoadingSponsor: true,
        updatedSponsor: false,
      };
    case SPONSOR.SPONSOR_UPDATE_FAILURE:
      return {
        ...state,
        isLoadingSponsor: false,
        updatedSponsor: false,
        error: action.payload,
      };
    case SPONSOR.SPONSOR_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingSponsor: false,
        sponsor: action.payload,
        updatedSponsor: true,
        error: null,
      };
    case SPONSOR.SPONSOR_CREATE:
      return {
        ...state,
        isLoadingSponsor: true,
        createdSponsor: false,
      };
    case SPONSOR.SPONSOR_CREATE_FAILURE:
      return {
        ...state,
        isLoadingSponsor: false,
        createdSponsor: false,
        error: action.payload,
      };
    case SPONSOR.SPONSOR_CREATE_SUCCESS:
      return {
        ...state,
        isLoadingSponsor: false,
        sponsor: action.payload,
        createdSponsor: true,
        error: null,
      };
    default:
      return state;
  }
};

export default userReducer;
