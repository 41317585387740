import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import { store } from "../../../store";
import { getOrder } from "../../../redux/actions/order";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import UserInfo from "../../ui/UserInfo";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { updateOrderStatus } from "../../../services/orderService";
import { createMultipleAORs } from "../../../services/aorService";
import {
  getShipmentById,
  updateShipmentReceived,
} from "../../../services/shipmentService";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../ui/utils/AlertUtils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export const ScreenAorForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderId = location.state.orderId;
  const centerNumber = location.state.centerNumber;
  const studyCode = location.state.studyCode;
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const order = useSelector((state) => state.order.order);
  const [isLoading, setIsLoading] = React.useState(true);
  const [shipment, setShipment] = React.useState(null);
  const shipmentIndex = location.state.shipmentIndex;
  const [materialsTypeMaterial, setMaterialsTypeMaterial] = React.useState([]);
  const [materialsTypeSupply, setMaterialsTypeSupply] = React.useState([]);
  const [materialsTypeReagent, setMaterialsTypeReagent] = React.useState([]);
  const [materialsTypeDocuments, setMaterialsTypeDocuments] = React.useState(
    []
  );
  const [confirmedDate, setConfirmedDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );

  const getShippingQuantity = React.useCallback(
    (supply) => {
      const productionsWithSameMaterial =
        shipment?.productions?.filter(
          (production) => production.materialId === supply.Material.id
        ) || [];
      return productionsWithSameMaterial.length;
    },
    [shipment]
  );

  React.useEffect(() => {
    setMaterialsTypeMaterial(
      order.Supplies.filter(
        (supply) =>
          supply.Material.type === "material" &&
          getShippingQuantity(supply) !== 0
      )
    );
    setMaterialsTypeSupply(
      order.Supplies.filter(
        (supply) =>
          supply.Material.type === "supply" && getShippingQuantity(supply) !== 0
      )
    );
    setMaterialsTypeReagent(
      order.Supplies.filter(
        (supply) =>
          supply.Material.type === "reagent" &&
          getShippingQuantity(supply) !== 0
      )
    );
    setMaterialsTypeDocuments(
      order.Supplies.filter(
        (supply) =>
          supply.Material.type === "document" &&
          getShippingQuantity(supply) !== 0
      )
    );
  }, [shipment, getShippingQuantity, order.Supplies]);

  const handleSubmitAor = async () => {
    const allRows = [
      ...materialsTypeMaterial,
      ...materialsTypeSupply,
      ...materialsTypeReagent,
      ...materialsTypeDocuments,
    ];

    // Check if there is any row where materialConform is false and comment is empty
    const invalidRow = allRows.find(
      (row) => !row.materialConform && !row.comment
    );

    if (invalidRow) {
      showErrorAlert(
        "A material was not checked as conform. If not conform, please describe the error in the comment section."
      );
      return;
    }
    // Check if any quantityReceived is blank
    const blankQuantityRow = allRows.find(
      (row) =>
        row.quantityReceived === "" ||
        row.quantityReceived === null ||
        row.quantityReceived === undefined
    );
    if (blankQuantityRow) {
      showErrorAlert("The quantity received cannot be blank");
      return;
    }

    const result = await showWarningAlert(
      "The Acknowledgement of Receipt will not be editable after submission."
    );

    if (result.isConfirmed) {
      const aors = allRows.map((row) => ({
        shipmentId: shipment.id,
        materialId: row.Material.id,
        quantityReceived: parseInt(row.quantityReceived),
        materialConform:
          row.materialConform === undefined ? false : row.materialConform,
        comment: row.comment,
      }));

      try {
        await createMultipleAORs(aors);
        await updateShipmentReceived(shipment.id, user.id, confirmedDate);

        // Calculate the total quantity shipped
        const totalQuantityShipped = order.shipments.reduce(
          (total, shipment) => {
            return total + parseInt(shipment.shippedQuantity);
          },
          0
        );

        const totalQuantity = parseInt(order.totalQuantity);

        // Check if total quantity shipped equals order total quantity
        if (totalQuantityShipped === totalQuantity) {
          await updateOrderStatus(order.id, "received");
        } else {
          await updateOrderStatus(order.id, "received 1/2");
        }
        showSuccessAlert(
          "The Acknowledgement of Receipt has been submitted"
        ).then(() => {
          navigate(`/order`, { state: { orderId, centerNumber, studyCode } });
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const CommentTextField = ({ inputVal, setInputVal }) => {
    return (
      <TextField
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        placeholder="comment"
      />
    );
  };

  const CommentCell = ({ row, rows, setRows }) => {
    const [inputVal, setInputVal] = React.useState(row.comment || "");

    const handleKeyDown = (event) => {
      if (event.key === " ") {
        event.stopPropagation();
      }
    };

    const handleBlur = () => {
      const newRows = rows.map((r) => {
        if (r.id === row.id) {
          return { ...r, comment: inputVal };
        }
        return r;
      });
      setRows(newRows);
    };

    return (
      <div onKeyDown={handleKeyDown} onBlur={handleBlur}>
        <CommentTextField inputVal={inputVal} setInputVal={setInputVal} />
      </div>
    );
  };

  React.useEffect(() => {
    getShipmentById(order.shipments[shipmentIndex].id).then((shipment) => {
      setShipment(shipment);
    });
  }, [order.shipments, shipmentIndex]);

  const CustomDataGrid = ({ rows, setRows, headerName }) => {
    const columns = [
      {
        field: "materialName",
        headerName: headerName,
        flex: 0.4,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.Material.name,
      },
      {
        field: "shippingQuantity",
        headerName: "Sent Quantity",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const productionsWithSameMaterial =
            shipment?.productions?.filter(
              (production) => production.materialId === params.row.Material.id
            ) || [];
          return <div>{productionsWithSameMaterial.length}</div>;
        },
        renderHeader: (params) => {
          return (
            <div
              style={{
                lineHeight: "1.5",
                whiteSpace: "normal",
                padding: "5px",
              }}
            >
              Quantity
              <br />
              Sent
            </div>
          );
        },
      },
      {
                field: "quantityReceived",
        headerName: "Quantity Received",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <TextField
              type="number"
              value={params.row.quantityReceived}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  const newRows = rows.map((row) => {
                    if (row.id === params.row.id) {
                      return { ...row, quantityReceived: value };
                    }
                    return row;
                  });
                  setRows(newRows);
                }
              }}
              placeholder="quantity"
              inputProps={{ min: 0 }}
            />
          );
        },
        renderHeader: (params) => {
          return (
            <div
              style={{
                lineHeight: "1.5",
                whiteSpace: "normal",
                padding: "5px",
              }}
            >
              Quantity
              <br />
              Received
            </div>
          );
        },
      },
      {
        field: "materialConform",
        headerName: "Material Conform ?",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <Checkbox
              checked={params.row.materialConform}
              onChange={(e) => {
                const newRows = rows.map((row) => {
                  if (row.id === params.row.id) {
                    return { ...row, materialConform: e.target.checked };
                  }
                  return row;
                });
                setRows(newRows);
              }}
            />
          );
        },
        renderHeader: (params) => {
          return (
            <div
              style={{
                lineHeight: "1.5",
                whiteSpace: "normal",
              }}
            >
              Material
              <br />
              Conform ?
            </div>
          );
        },
      },
      // comment section for non-conform material
      {
        field: "comment",
        headerName: "If not, describe the error.",
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <CommentCell row={params.row} rows={rows} setRows={setRows} />
        ),
      },
    ];

    if (rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        rowHeight={40}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[30, 50]}
        disableSelectionOnClick
        hideFooter
        autoHeight
        hideScrollbar
      />
    );
  };

  // fetch order
  React.useEffect(() => {
    store.dispatch(getOrder(orderId)).then(() => setIsLoading(false));
  }, [orderId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ p: 2 }}>
          <div style={{ width: "100%" }}>
            <TextField
              value="ACKNOWLEDGEMENT OF RECEIPT"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center", fontWeight: "bold" },
              }}
              sx={{ marginBottom: 2 }}
              fullWidth
            />
            <TextField
              value="MATERIAL ORDER"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            <CustomDataGrid
              rows={materialsTypeMaterial}
              setRows={setMaterialsTypeMaterial}
              headerName="Material"
            />
            <CustomDataGrid
              rows={materialsTypeSupply}
              setRows={setMaterialsTypeSupply}
              headerName="Supply"
            />
            <CustomDataGrid
              rows={materialsTypeReagent}
              setRows={setMaterialsTypeReagent}
              headerName="Reagent"
            />
            <CustomDataGrid
              rows={materialsTypeDocuments}
              setRows={setMaterialsTypeDocuments}
              headerName="Document"
            />
            {adminUser && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    label="Date of Reception"
                    name="confirmedDate"
                    value={dayjs(confirmedDate)}
                    onChange={(newValue) => {
                      setConfirmedDate(newValue.format("YYYY-MM-DD"));
                    }}
                    format="DD/MM/YYYY"
                    maxDate={dayjs()}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </div>
          <UserInfo
            title={"PERSON IN CHARGE OF RECEPTION"}
            user={user}
            date={new Date().toLocaleDateString()}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitAor}
              style={{ padding: "16px 88px", margin: "16px" }}
              size="large"
            >
              Submit
            </Button>
          </div>
        </Box>
      </Paper>
    </Grid>
  );
};
export default ScreenAorForm;
