import React from 'react';
import { TextField, Box } from '@mui/material';

// USED IN ORDER AND SHIPMENT TO HAVE SHIPPING ADDRESS DISPLAYED

const ReadOnlyTextField = ({ title, value, multiline, maxRows, onDoubleClick }) => (
    <Box sx={{ p: 2 }}>
        <TextField
            value={title}
            name={title}
            InputProps={{
                readOnly: true,
            }}
            inputProps={{
                style: { textAlign: "center" },
            }}
            fullWidth
        />

        <TextField
            value={value}
            name={value}
            InputProps={{
                readOnly: true,
            }}
            inputProps={{
                style: { textAlign: "center", lineHeight: "1.3" }, 
                onDoubleClick: onDoubleClick
            }}
            fullWidth
            maxRows={maxRows}
            multiline={multiline}
        />
    </Box>
);

export default ReadOnlyTextField;