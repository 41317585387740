import { instance } from "../axios";

export const getAORsByShipmentId = async (shipmentId) => {
  const response = await instance.get(`/aor/shipment/${shipmentId}`);
  return response.data;
};

export const createMultipleAORs = async (aors) => {
  const aorRequests = aors.map((aor) => instance.post(`/aor`, aor));
  await Promise.all(aorRequests);
};
