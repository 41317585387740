import { CENTER } from "../constants";
import { instance } from "../../axios";

let getCenters = (studyId) => {
  return (dispatch) => {
    dispatch({
      type: CENTER.CENTER_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/center/study/${studyId}`,
    })
      .then((results) => {
        dispatch({
          type: CENTER.CENTER_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CENTER.CENTER_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

// Get one center
let getCenter = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: CENTER.CENTER_GET_ONE,
    });
    instance({
      method: "GET",
      url: `/center/${centerId}`,
    })
      .then((results) => {
        dispatch({
          type: CENTER.CENTER_GET_ONE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CENTER.CENTER_GET_ONE_FAILURE,
          payload: error,
        });
      });
  };
};

// Get the center data for the center page with employees and monitors
let getCenterData = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: CENTER.CENTER_GET_ONE,
    });
    instance({
      method: "GET",
      url: `/center/${centerId}/data`,
    })
      .then((results) => {
        dispatch({
          type: CENTER.CENTER_GET_ONE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CENTER.CENTER_GET_ONE_FAILURE,
          payload: error,
        });
      });
  };
};

let getMonitorCenters = (userId) => {
  return (dispatch) => {
    dispatch({
      type: CENTER.CENTER_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/center/monitor/${userId}`,
    })
      .then((results) => {
        dispatch({
          type: CENTER.CENTER_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CENTER.CENTER_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
}

let createCenter = (body) => {
  return (dispatch) => {
    dispatch({
      type: CENTER.CENTER_CREATE,
    });
    instance
      .post("/center", body)
      .then((results) => {
        dispatch({
          type: CENTER.CENTER_CREATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CENTER.CENTER_CREATE_FAILURE,
          payload: error,
        });
      });
  };
};

let updateCenter = (centerId, body) => {
  return (dispatch) => {
    dispatch({
      type: CENTER.CENTER_UPDATE,
    });
    instance
      .put(`/center/${centerId}`, body)
      .then((results) => {
        dispatch({
          type: CENTER.CENTER_UPDATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CENTER.CENTER_UPDATE_FAILURE,
          payload: error,
        });
      });
  };
}

let deleteCenter = (centerId) => {  
  return (dispatch) => {
    dispatch({
      type: CENTER.CENTER_DELETE,
    });
    instance
      .delete(`/center/${centerId}`)
      .then((results) => {
        dispatch({
          type: CENTER.CENTER_DELETE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CENTER.CENTER_DELETE_FAILURE,
          payload: error,
        });
      });
  };
}


export { getCenters, getCenter, getCenterData, getMonitorCenters, createCenter, updateCenter, deleteCenter };
