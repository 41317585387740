import { SPONSOR } from "../constants";
import { instance } from "../../axios";

let getSponsors = () => {
  return (dispatch) => {
    dispatch({
      type: SPONSOR.SPONSOR_GET_ALL,
    });
    instance({
      method: "GET",
      url: "/sponsor",
    })
      .then((results) => {
        dispatch({
          type: SPONSOR.SPONSOR_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SPONSOR.SPONSOR_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let updateSponsor = (body) => {
  return (dispatch) => {
    dispatch({
      type: SPONSOR.SPONSOR_UPDATE,
    });
    return instance
      .put(`/sponsor/${body.id}`, body)
      .then((results) => {
        dispatch({
          type: SPONSOR.SPONSOR_UPDATE_SUCCESS,
          payload: results.data,
        });
        return results.data;
      })
      .catch((error) => {
        dispatch({
          type: SPONSOR.SPONSOR_UPDATE_FAILURE,
          payload: error,
        });
        throw error;
      });
  };
}

let createSponsor = (body) => {
  return (dispatch) => {
    dispatch({
      type: SPONSOR.SPONSOR_CREATE,
    });
    return instance
      .post("/sponsor", body)
      .then((results) => {
        dispatch({
          type: SPONSOR.SPONSOR_CREATE_SUCCESS,
          payload: results.data,
        });
        return results.data;
      })
      .catch((error) => {
        dispatch({
          type: SPONSOR.SPONSOR_CREATE_FAILURE,
          payload: error,
        });
        throw error;
      });
  };
}
export { getSponsors, createSponsor, updateSponsor };
