import { MATERIAL } from "../constants";

const initialState = {
  materials: [],
  material: null,
  isLoadingMaterials: false,
  isLoadingMaterial: false,
  createdMaterial: false,
  updatedMaterial: false,
  deletedMaterial: false,
  error: null,
};

const materialReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case MATERIAL.MATERIAL_GET_ALL:
      return {
        ...state,
        tokenAccess: null,
        isLoadingMaterials: true,
        createdMaterial: false,
        deletedMaterial: false,
      };
    case MATERIAL.MATERIAL_GET_ALL_FAILURE:
      return {
        ...state,
        isLoadingMaterials: false,
        createdMaterial: false,
        error: action.payload,
      };
    case MATERIAL.MATERIAL_GET_ALL_SUCCESS:
      return {
        ...state,
        isLoadingMaterials: false,
        materials: action.payload,
        createdMaterial: false,
        updatedMaterial: false,
        error: null,
      };
    case MATERIAL.MATERIAL_CREATE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingMaterial: false,
        createdMaterial: false,
        deletedMaterial: false,
      };
    case MATERIAL.MATERIAL_CREATE_FAILURE:
      return {
        ...state,
        isLoadingMaterial: false,
        createdMaterial: false,
        error: action.payload,
      };
    case MATERIAL.MATERIAL_CREATE_SUCCESS:
      return {
        ...state,
        isLoadingMaterial: false,
        material: action.payload,
        createdMaterial: true,
        error: null,
      };
    case MATERIAL.MATERIAL_EDIT:
      return {
        ...state,
        tokenAccess: null,
        isLoadingMaterial: true,
        updatedMaterial: false,
        deletedMaterial: false,
      };
    case MATERIAL.MATERIAL_EDIT_FAILURE:
      return {
        ...state,
        isLoadingMaterial: false,
        updatedMaterial: false,
        error: action.payload,
      };
    case MATERIAL.MATERIAL_EDIT_SUCCESS:
      return {
        ...state,
        isLoadingMaterial: false,
        material: action.payload,
        updatedMaterial: true,
        error: null,
      };
    case MATERIAL.MATERIAL_DELETE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingMaterial: true,
        deletedMaterial: false,
      };
    case MATERIAL.MATERIAL_DELETE_FAILURE:
      return {
        ...state,
        isLoadingMaterial: false,
        deletedMaterial: false,
        error: action.payload,
      };
    case MATERIAL.MATERIAL_DELETE_SUCCESS:
      return {
        ...state,
        isLoadingMaterial: false,
        material: action.payload,
        deletedMaterial: true,
        error: null,
      };
    default:
      return state;
  }
};

export default materialReducer;
