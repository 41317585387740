import { instance } from "../axios";

export const createProtocol = async (body) => {
  const response = await instance.post(`/protocol`, body);
  return response.data;
};

export const deleteProtocol = async (id) => {
  const response = await instance.delete(`/protocol/${id}`);
  return response.data;
}

export const addCenterToProtocol = async (protocolId, centerId) => {
  const response = await instance.post(`/protocol/${protocolId}/centers/${centerId}`);
  return response.data;
}

export const removeCenterFromProtocol = async (protocolId, centerId) => {
  try {
    const response = await instance.delete(`/protocol/${protocolId}/centers/${centerId}`);
    return response.data;
  } catch (error) {
    console.error("Error removing center from protocol:", error);
    throw error;
  }
}

export const addMaterialToProtocol = async (protocolId, materialId) => {
  const response = await instance.post(`/protocol/${protocolId}/materials/${materialId}`);
  return response.data;
}

export const getProtocols = async (studyId) => {
  const response = await instance.get(`/protocol/${studyId}`);
  return response.data;
}

export const getCentersForProtocol = async (id) => {
  const response = await instance.get(`/protocol/${id}/centers`);
  return response.data;
}

export const updateProtocolStartDate = async (id, date) => {
  const response = await instance({
    method: "PUT",
    url: `/protocol/${id}`,
    data: {
      startDate: date,
    },
  });
  return response.data;
}

export const getCenterProtocols = async (centerId) => {
  const response = await instance.get(`protocol/center/${centerId}`);
  return response.data;
}