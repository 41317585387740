import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { ModalProduction } from "../../modals/Production";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import {
  getProductions,
  getProductionsByCenter,
  getExpiringProductions,
  updateProduction,
  deleteProduction,
} from "../../../redux/actions/production";
import { store } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import CSVDownloader from "../../ui/CSVDownloader";
import {
  destroyProductions,
  updateProductionComment,
  attributeProduction,
  updateProductionStatus,
} from "../../../services/productionService";
import { getToday } from "../../ui/DateUtils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../ui/utils/AlertUtils";
import 'dayjs/locale/en-gb';

dayjs.locale('en-gb');

const SPACING = 50;

export const ScreenProductions = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const productions = useSelector((state) => state.production.productions);
  const cdlUser = useSelector(
    (state) => state.user.user.organization === "CDL"
  );
  const admin = useSelector((state) => state.user.user.role === "admin");
  const employeeUser = useSelector(
    (state) =>
      state.user.user.organization === "CDL" &&
      state.user.user.role === "employee"
  );
  const centerUser = useSelector(
    (state) => state.user.user.organization === "Center"
  );
  const centers = useSelector((state) => state.center.centers);
  const reduxCenterId = useSelector((state) => state.user.user.centerId);
  const studyId = location.state?.studyId;
  const studyCode = location.state?.studyCode;
  const centerId = centerUser ? reduxCenterId : location.state?.centerId;
  const expiring = location.state?.expiring;
  const isLoading = useSelector(
    (state) => state.production.isLoadingProductions
  );
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [sortedProductions, setSortedProductions] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [commentUpdated, setCommentUpdated] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false); // if patient number is empty
  const [showDateAlert, setShowDateAlert] = React.useState(false);
  const centerNumber = location.state?.centerNumber;
  const [destructionDate, setDestructionDate] = React.useState(dayjs());
  const [openDialog, setOpenDialog] = React.useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const [centerDialogOpen, setCenterDialogOpen] = React.useState(false);
  const [referenceDialogOpen, setReferenceDialogOpen] = React.useState(false);
  const [selectedProduction, setSelectedProduction] = React.useState(null);
  const [selectedCenterId, setSelectedCenterId] = React.useState("");
  const [selectedStatusRow, setSelectedStatusRow] = React.useState(null);
  const [productionDate, setProductionDate] = React.useState("");
  const [expirationDate, setExpirationDate] = React.useState("");
  const [batch, setBatch] = React.useState("");
  const [reference, setReference] = React.useState("");
  const [sendingAuthorization, setSendingAuthorization] = React.useState("");
  const [patientNumber, setPatientNumber] = React.useState("");
  const [attributionDate, setAttributionDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );

  const columns = [
    {
      field: "centerNumber",
      headerName: "Center",
      valueGetter: (params) => {
        return params.row.Center ? params.row.Center.centerNumber : "At CDL";
      },
      filterParams: {
        valueOptions: ["Option 1", "Option 2", "Option 3", "Option 4"],
      },
    },
    { field: "batchNumber", headerName: "Batch", width: 80 },
    { field: "reference", headerName: "Reference", width: 100 },
    {
      field: "materialName",
      headerName: "Material type",
      flex: 2,
      minWidth: 250,
      valueGetter: (params) => {
        return params.row.Material.name;
      },
    },
    {
      field: "productionDate",
      type: "date",
      headerName: "Production date",
      minWidth: 150,
      headerAlign: "left",
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Production date <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return new Date(params.value);
      },
      renderCell: (params) => {
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "expirationDate",
      type: "date",
      headerName: "Expiration date",
      minWidth: 150,
      headerAlign: "left",
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Expiration date
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        if (!cdlUser && params.row.status === "attributed to patient") {
          return new Date(); // return a default date when used
        } else {
          return new Date(params.value); // converts the value to a Date object
        }
      },
      renderCell: (params) => {
        const expirationDate = moment(params.value);
        const now = moment();
        const isExpired =
          (params.row.status === "at center" ||
            params.row.status === "produced") &&
          expirationDate.isBefore(now);
        const isExpiring =
          (params.row.status === "at center" ||
            params.row.status === "produced") &&
          expirationDate.isBefore(now.add(30, "days"));
        const dateFormat = employeeUser ? "MM/DD/YYYY" : "DD/MM/YYYY";
        return (
          <div
            style={
              isExpired
                ? { color: "red" }
                : isExpiring
                ? { color: "darkorange" }
                : {}
            }
          >
            {expirationDate.format(dateFormat)}
          </div>
        );
      },
    },
    { field: "status", headerName: "Status", flex: 1.3, minWidth: 140 },
    {
      field: "sendingAuthorization",
      type: "date",
      headerName: "Sending Authorization",
      headerAlign: "left",
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Sending Authorization
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return new Date(params.value);
      },
      renderCell: (params) => {
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "Shipment.packingSlip",
      headerName: "Airwaybill",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.Shipment ? params.row.Shipment.packingSlip : "",
    },
    {
      field: "patientNumber",
      headerName: "Patient Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "attributionDate",
      headerName: "Attribution Date",
      headerAlign: "left",
      minWidth: 150,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Attribution date <br />
          <span style={{ fontSize: "x-small" }}> (DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return params.value ? moment(params.value).format("DD/MM/YYYY") : "";
      },
      renderCell: (params) => {
        if (params.value === null) {
          return null;
        }

        const attributionDate = moment(params.value, "DD/MM/YYYY");
        const expirationDate = moment(params.row.expirationDate);
        const isAfterExpiration = attributionDate.isAfter(expirationDate);
        return (
          <div style={isAfterExpiration ? { color: "red" } : {}}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "destructionDate",
      headerName: "Destruction date",
      headerAlign: "left",
      minWidth: 150,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Destruction date <br />
          <span style={{ fontSize: "x-small" }}> (DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return params.value ? moment(params.value).format("DD/MM/YYYY") : "";
      },
    },
    {
      field: "comment",
      headerName: "Comments",
      flex: 1.3,
      minWidth: 200,
      editable: true,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Destroy expiring productions
  const handleDestroyExpiringProductions = async () => {
    setOpenDialog(true);
  };

  const handleDateChange = async (date) => {
    setDestructionDate(date);
  };

  const handleConfirmDestruction = async () => {
    setOpenDialog(false);

    // After date selection, display the confirmation dialog
    Swal.fire({
      title: `Do you really want to destroy these productions?`,
      text: `The destruction date will be ${dayjs(destructionDate).format(
        "YYYY-MM-DD"
      )}`,
      showCancelButton: true,
      confirmButtonColor: "#1e3179",
      confirmButtonText: "Yes, destroy them",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then(async (result) => {
      // If the user confirmed the action, destroy the productions
      if (result.isConfirmed) {
        try {
          await destroyProductions(selectedRows, destructionDate);
          showSuccessAlert("Productions destroyed successfully");
          setCommentUpdated(true);
        } catch (error) {
          console.error("Error destroying productions:", error);
        }
      }
    });
  };

   const handleCSVSelectedProductions = () => {
    const selectedProductions = productions.filter((production) =>
      selectedRows.includes(production.id)
    );
  
    const includedColumns = cdlUser
      ? columns.map((column) => column.field)
      : ["reference", "expirationDate", "materialName", "status"].concat(
          centerId ? [] : ["centerNumber"]
        );
    const visibleColumns = columns.filter((column) =>
      includedColumns.includes(column.field)
    );
  
    const printableData = selectedProductions.map((production) =>
      visibleColumns.reduce((obj, column) => {
        if (
          (column.field === "expirationDate" ||
            column.field === "destructionDate" ||
            column.field === "attributionDate" ||
            column.field === "productionDate" ||
            column.field === "sendingAuthorization") &&
          production[column.field] != null
        ) {
          obj[column.headerName] = new Date(production[column.field]);
        } else if (
          (column.field === "expirationDate" ||
            column.field === "destructionDate" ||
            column.field === "attributionDate" ||
            column.field === "productionDate" ||
            column.field === "sendingAuthorization") &&
          production[column.field] == null
        ) {
          obj[column.headerName] = "";
        } else {
          obj[column.headerName] = column.valueGetter
            ? column.valueGetter({ row: production })
            : production[column.field];
        }
        return obj;
      }, {})
    );
  
    return printableData;
  };

  const handleRowUpdate = (params) => {
    const updatedComment = params.comment;
    const productionId = params.id;

    // Get the original comment from the productions state variable
    const originalProduction = productions.find(
      (production) => production.id === productionId
    );
    const originalComment = originalProduction
      ? originalProduction.comment
      : null;

    // Only call updateProductionComment if the comment has changed
    if (originalComment !== updatedComment) {
      updateProductionComment(productionId, updatedComment)
        .then((data) => {
          if (data) {
            setCommentUpdated(true); //trigger the useEffect hook
            setShowSuccessMessage(true);

            // Hide the success message after 3 seconds
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          showErrorAlert("Failed to update comment");
        });
    }
  };

  const handleCellDoubleClick = (params) => {
    if (params.field === "status" && params.value === "at center" && cdlUser) {
      setSelectedStatusRow(params.row);
      setStatusDialogOpen(true);
    } else if (params.field === "centerNumber" && admin) {
      setSelectedProduction(params.row);
      setCenterDialogOpen(true);
    } else if (params.field === "reference" && admin) {
      setSelectedProduction(params.row);
      setReferenceDialogOpen(true);
    }
  };

  const handleTransferProduction = async () => {
    setCenterDialogOpen(false);
    const productionId = selectedProduction.id;
    const centerId = selectedCenterId;
    const status = "at center";
    try {
      await updateProductionStatus(productionId, status, centerId);
      showSuccessAlert("Production transfered to center successfully");
      setCommentUpdated(true);
    } catch (error) {
      console.error(error);
      showErrorAlert("Failed to transfer production to center");
    }
  };

  // Update reference
  const handleUpdateReference = async () => {
    setReferenceDialogOpen(false);
    const productionId = selectedProduction.id;
    try {
      const body = {};
      if (batch) body.batchNumber = batch;
      if (reference) body.reference = reference;
      if (productionDate) body.productionDate = productionDate;
      if (expirationDate) body.expirationDate = expirationDate;
      if (sendingAuthorization)
        body.sendingAuthorization = sendingAuthorization;
      await store.dispatch(updateProduction(productionId, body));
      showSuccessAlert("Production reference updated successfully");
      setCommentUpdated(true);
      handleCloseReferenceDialog();
    } catch (error) {
      showErrorAlert("Failed to update production reference");
    }
  };

  const handleCloseReferenceDialog = () => {
    setReferenceDialogOpen(false);
    setSelectedProduction(null);
    setReference("");
    setBatch("");
    setProductionDate("");
    setExpirationDate("");
    setSendingAuthorization("");
  };

  const handleDeleteProduction = async () => {
    setReferenceDialogOpen(false);
    const productionId = selectedProduction.id;
    try {
      const result = await showWarningAlert(
        "Do you really want to delete this production?"
      );
      if (result.isConfirmed) {
        await store.dispatch(deleteProduction(productionId));
        showSuccessAlert("Production deleted successfully");
        setCommentUpdated(true);
      }
    } catch (error) {
      console.error(error);
      showErrorAlert("Failed to delete production");
    }
  };

  const handleCloseStatusDialog = () => {
    setStatusDialogOpen(false);
    setSelectedStatusRow(null);
    setPatientNumber("");
    setAttributionDate(dayjs().format("YYYY-MM-DD"));
  };

  const handleStatusFormSubmit = async (patientNumber, attributionDate) => {
    if (selectedStatusRow && patientNumber !== "") {
      const productionId = selectedStatusRow.id;
      handleCloseStatusDialog();
      setShowAlert(false);

      const result = await showWarningAlert(
        "You won't be able to revert this!"
      );
      if (result.isConfirmed) {
        try {
          await attributeProduction(
            productionId,
            attributionDate,
            patientNumber
          );
          showSuccessAlert("Production attributed to patient successfully");
          setCommentUpdated(true);
        } catch (error) {
          console.error(error);
          showErrorAlert("Failed to attribute production to patient");
        }
      }
    } else if (patientNumber === "") {
      setShowAlert(true);
    }
  };

  // Fetch productions
  React.useEffect(() => {
    const fetchData = async () => {
      if (expiring) {
        await store.dispatch(getExpiringProductions(centerId));
      } else if (centerId) {
        await store.dispatch(getProductionsByCenter(centerId));
      } else if (studyId) {
        await store.dispatch(getProductions(studyId));
      } else {
        navigate("/studies");
      }
    };
    fetchData();
    setCommentUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, centerId, expiring, commentUpdated]);

  // Sort productions and filter them based on user type
  React.useEffect(() => {
    if (productions) {
      // if not cdlUser, remove from productions all that have centerId null
      let sorted;
      if (!cdlUser) {
        sorted = productions.filter(
          (production) => production.centerId !== null
        );
      } else {
        sorted = [...productions];
      }

      const statusOrder = {
        produced: 1,
        "at center": 2,
        "attributed to patient": 3,
        "in transit": 4,
        destroyed: 5,
      };

      sorted.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
      setSortedProductions(sorted);
    }
  }, [cdlUser, productions]);

  const columnsToShow = React.useMemo(() => {
    let filteredColumns = columns;

    if (centerId) {
      // If center, filter out the centerNumber column
      filteredColumns = filteredColumns.filter(
        (column) => column.field !== "centerNumber"
      );
    }

    if (centerUser) {
      // If centerUser, filter out columns
      filteredColumns = filteredColumns.filter(
        (column) =>
          column.field !== "batchNumber" &&
          column.field !== "productionDate" &&
          column.field !== "sendingAuthorization" &&
          column.field !== "comment" &&
          column.field !== "destructionDate" &&
          column.field !== "patientNumber" &&
          column.field !== "attributionDate" &&
          column.field !== "Shipment.packingSlip"
      );
    }
    return filteredColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId, centerUser]);

  React.useEffect(() => {
    if (
      selectedStatusRow !== null &&
      dayjs().isAfter(dayjs(selectedStatusRow.expirationDate))
    ) {
      setShowDateAlert(true);
    } else {
      setShowDateAlert(false);
    }
  }, [selectedStatusRow]);

  // to assign center to production
  React.useEffect(() => {
    setSelectedCenterId(selectedProduction?.centerId || "");
  }, [selectedProduction]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          height: "100%",
          overflow: "auto",
        }}
      >
        <div style={{ width: "100%", height: "85vh" }}>
          <DataGrid
            autoWidth
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            disableColumnResize={false}
            rows={sortedProductions}
            columns={columnsToShow.map((column) => ({
              ...column,
              cellClassName: "my-datagrid-cell",
            }))}
            loading={isLoading}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            rowHeight={30}
            checkboxSelection
            processRowUpdate={handleRowUpdate}
            onRowSelectionModelChange={(selection) => {
              setSelectedRows(selection);
            }}
            onCellDoubleClick={handleCellDoubleClick}
            sx={{
              "& .my-datagrid-cell": {
                fontSize: "0.75rem",
              },
            }}
          />
        </div>
        {/*  */}
        {/* EDIT OR DELETE THE PRODUCTION */}
        {/*  */}
        <Dialog
          PaperProps={{
            style: { padding: "30px" },
          }}
          open={referenceDialogOpen}
          onClose={handleCloseReferenceDialog}
        >
          <DialogTitle>
            Edit Production - {selectedProduction?.Material.name}
          </DialogTitle>

          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Batch"
                  defaultValue={
                    selectedProduction ? selectedProduction.batchNumber : ""
                  }
                  onChange={(e) => setBatch(e.target.value)}
                  sx={{ margin: 2 }}
                />
                <TextField
                  label="Reference"
                  defaultValue={
                    selectedProduction ? selectedProduction.reference : ""
                  }
                  onChange={(e) => setReference(e.target.value)}
                  sx={{ margin: 2 }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Production Date"
                  defaultValue={
                    selectedProduction
                      ? formatDate(selectedProduction.productionDate)
                      : ""
                  }
                  readOnly
                  sx={{ margin: 2 }}
                />
                <DatePicker
                adapterLocale="en-gb"
                  label="New Production Date"
                  selected={
                    selectedProduction
                      ? new Date(selectedProduction.productionDate)
                      : null
                  }
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    setProductionDate(date.format("YYYY-MM-DD"));
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Expiration Date"
                  defaultValue={
                    selectedProduction
                      ? formatDate(selectedProduction.expirationDate)
                      : ""
                  }
                  readOnly
                  sx={{ margin: 2 }}
                />
                <DatePicker
                  id="date-picker"
                  label="New Expiration Date"
                  selected={
                    selectedProduction
                      ? new Date(selectedProduction.expirationDate)
                      : null
                  }
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    setExpirationDate(date.format("YYYY-MM-DD"));
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Sending Authorization"
                  defaultValue={
                    selectedProduction
                      ? formatDate(selectedProduction.sendingAuthorization)
                      : ""
                  }
                  readOnly
                  sx={{ margin: 2 }}
                />
                <DatePicker
                  selected={
                    selectedProduction
                      ? new Date(selectedProduction.sendingAuthorization)
                      : null
                  }
                  onChange={(date) => {
                    setSendingAuthorization(date.format("YYYY-MM-DD"));
                  }}
                  format="DD/MM/YYYY"
                  label="New Authorization Date"
                />
              </div>
            </LocalizationProvider>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ padding: "16px", margin: "16px" }}
              onClick={handleDeleteProduction}
            >
              Delete production
            </Button>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              style={{ padding: "16px" }}
              onClick={handleCloseReferenceDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ padding: "16px 32px", margin: "16px" }}
              onClick={handleUpdateReference}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        {/*  */}
        {/* ATTRIBUTE A REFERENCE TO A PATIENT */}
        {/*  */}
        <Dialog
          PaperProps={{
            style: { padding: "20px" },
          }}
          open={statusDialogOpen}
          onClose={handleCloseStatusDialog}
        >
          <DialogTitle>Attribute to a patient </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {selectedStatusRow ? selectedStatusRow.Material.name : ""}{" "}
            </DialogContentText>
            <DialogContentText>
              Reference: {selectedStatusRow ? selectedStatusRow.reference : ""}
            </DialogContentText>
            <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
              <TextField
                label="Patient Number"
                onChange={(e) => setPatientNumber(e.target.value)}
                sx={{ marginRight: 1 }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  label="Attribution Date"
                  name="attributionDate"
                  value={dayjs(attributionDate)}
                  onChange={(newValue) => {
                    if (
                      dayjs(newValue).isAfter(
                        dayjs(selectedStatusRow.expirationDate)
                      )
                    ) {
                      setShowDateAlert(true);
                    } else {
                      setShowDateAlert(false);
                      setAttributionDate(newValue.format("YYYY-MM-DD"));
                    }
                  }}
                  format="DD/MM/YYYY"
                  maxDate={dayjs()} // Disable dates after today
                  sx={{ width: "50%" }}
                />
              </LocalizationProvider>
            </Box>
            {showAlert && (
              <div style={{ color: "red", marginTop: "10px" }}>
                Please enter a patient number
              </div>
            )}
            {showDateAlert && (
              <div style={{ color: "red", marginTop: "10px" }}>
                Selected date is after the expiration date
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStatusDialog}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ padding: "16px", margin: "16px" }}
              onClick={() =>
                handleStatusFormSubmit(patientNumber, attributionDate)
              }
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/*  */}
        {/* TRANSFER PRODUCTION TO A CENTER */}
        {/*  */}
        <Dialog
          PaperProps={{
            style: { padding: "20px" },
          }}
          open={centerDialogOpen}
          onClose={() => setCenterDialogOpen(false)}
        >
          <DialogTitle>Transfer a production to a center</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {selectedProduction ? selectedProduction.Material.name : ""}{" "}
            </DialogContentText>
            <DialogContentText>
              Reference:{" "}
              {selectedProduction ? selectedProduction.reference : ""}
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Select a center</DialogContentText>
            <Select
              value={selectedCenterId || "At CDL"}
              onChange={(e) => setSelectedCenterId(e.target.value)}
            >
              {selectedProduction?.centerId === null && (
                <MenuItem value="At CDL">At CDL</MenuItem>
              )}
              {centers.map((center) => (
                <MenuItem key={center.id} value={center.id}>
                  {center.centerNumber}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCenterDialogOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ padding: "16px", margin: "16px" }}
              onClick={handleTransferProduction}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {showSuccessMessage && (
          <div
            style={{
              color: "#155724",
              backgroundColor: "#d4edda",
              borderColor: "#c3e6cb",
              padding: "0.75em",
              marginBottom: "1em",
              border: "1px solid transparent",
              borderRadius: ".25em",
            }}
          >
            The production comment has been updated
          </div>
        )}
        {selectedRows.length > 0 && (
          <Grid container justifyContent="space-around" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                onClick={handleDestroyExpiringProductions}
                variant="contained"
                color="secondary"
                size="large"
                style={{ padding: "16px 8px", margin: "16px" }}
                fullWidth
              >
                Destroy Selected Productions
              </Button>
              <Dialog
                PaperProps={{
                  style: { padding: "20px" },
                }}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
              >
                <DialogTitle>Select a destruction date</DialogTitle>
                <DialogContent>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      value={destructionDate || dayjs()}
                      onChange={handleDateChange}
                      maxDate={dayjs()}
                      components={{
                        textField: TextField,
                      }}
                    />
                  </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ padding: "16px", margin: "16px" }}
                    onClick={handleConfirmDestruction}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <CSVDownloader
              data={handleCSVSelectedProductions()}
              filename={`${studyCode}${
                centerNumber ? `_${centerNumber}` : ""
              }_productions_${getToday()}`}
              name="selected productions"
            />
          </Grid>
        )}
      </Paper>
      {cdlUser && !centerId && !employeeUser && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setVisibleCreateEdit(true);
            }}
            style={{ position: "absolute", right: SPACING, bottom: SPACING }}
          >
            <AddIcon />
          </Fab>
          <ModalProduction
            visible={visibleCreateEdit}
            studyId={studyId}
            studyCode={studyCode}
            onClose={(refresh) => {
              setVisibleCreateEdit(false);
              if (refresh) {
                store.dispatch(getProductions(studyId));
              }
            }}
          ></ModalProduction>
        </>
      )}
    </Grid>
  );
};
