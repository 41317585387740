import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import AsyncStorage from "@react-native-community/async-storage";
import rootReducer from "./redux/reducers/root";
import productionReducer from "./redux/reducers/production";
import studyReducer from "./redux/reducers/study";
import orderReducer from "./redux/reducers/order";
import materialReducer from "./redux/reducers/material";
import userReducer from "./redux/reducers/user";
import centerReducer from "./redux/reducers/center";
import sponsorReducer from "./redux/reducers/sponsor";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const initialState = {};
const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: [
    "tokenAccess",
    "response",
    "production",
    "material",
    "order",
  ],
};

let combinedReducers = combineReducers({
  root: rootReducer,
  production: productionReducer,
  study: studyReducer,
  order: orderReducer,
  material: materialReducer,
  user: userReducer,
  center: centerReducer,
  sponsor: sponsorReducer,
});

const logger = (store) => (next) => (action) => {
  let result = next(action);
  return result;
};

const middleware = [thunk, logger];

const persistedReducer = persistReducer(persistConfig, combinedReducers);
const store = createStore(
  persistedReducer,
  initialState,
  compose(applyMiddleware(...middleware))
);
const persistor = persistStore(store);
export { store, persistor };
