import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { createMaterial } from "../../redux/actions/material";
import { store } from "../../store";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as React from "react";
import { showSuccessAlert } from "../ui/utils/AlertUtils";
import { getProtocols } from "../../services/protocolService";

export const ModalMaterial = ({ visible, onClose, studyId, studyCode }) => {
  const [name, setName] = React.useState("");
  const [type, setType] = React.useState("");
  const [status, setStatus] = React.useState("");
  const isLoading = useSelector((state) => state.material.isLoadingMaterial);
  const created = useSelector((state) => state.material.createdMaterial);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [protocols, setProtocols] = React.useState([]);
  const [selectedProtocolId, setSelectedProtocolId] = React.useState("");

  const handleProtocolChange = (event) => {
    setSelectedProtocolId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name && type && status && selectedProtocolId) {
      setName("");
      setType("");
      setStatus("");
      store.dispatch(
        createMaterial({
          name: name,
          type: type,
          status: status,
          studyId: studyId,
          protocolId: selectedProtocolId,
        })
      ).then(() => onClose(true));
    } else {
      setErrorMessage("Please fill all required fields");
    }
  };

  React.useEffect(() => {
    if (created) {
      showSuccessAlert("The material was successfully created");
    }
  }, [created]);

  React.useEffect(() => {
    const fetchProtocols = async () => {
      const protocols = await getProtocols(studyId);
      setProtocols(protocols);
    };

    fetchProtocols();
  }, [studyId]);

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Create a material
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Please indicate the following field to create a material
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                required
                name="name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                id="input-name"
                label="Name"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                label="Type"
                id="type"
                name="type"
                value={type}
                onChange={(event) => setType(event.target.value)}
                required
              >
                <MenuItem value={"material"}>Kit</MenuItem>
                <MenuItem value={"supply"}>Supply</MenuItem>
                <MenuItem value={"reagent"}>Reagent</MenuItem>
                <MenuItem value={"document"}>Document</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                label="Status"
                id="status"
                name="status"
                value={status}
                onChange={(event) => setStatus(event.target.value)}
                required
              >
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"finished"}>Finished</MenuItem>
                <MenuItem value={"planned"}>Planned</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth >
              <InputLabel id="select-protocol">Protocol</InputLabel>
              <Select
                labelId="select-protocol"
                id="select-protocol"
                value={selectedProtocolId}
                onChange={handleProtocolChange}
                name="select-protocol"
                label="Protocol"
              >
                {protocols.map((protocol) => (
                  <MenuItem key={protocol.id} value={protocol.id}>
                    {protocol.reference}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="study-label">Study</InputLabel>
              <Select
                labelId="study-label"
                id="study-select"
                value={studyCode}
                input={<OutlinedInput label="Study" />}
                disabled
              >
                <MenuItem value={studyCode}>{studyCode}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, padding: 2}}
            >
              Create
            </Button>
          </Grid></Grid>
          {errorMessage && 
            <p style={{
              color: '#721c24',
              backgroundColor: '#f8d7da',
              borderColor: '#f5c6cb',
              padding: '.75rem 1.25rem',
              marginBottom: '1rem',
              border: '1px solid transparent',
              borderRadius: '.25rem'
            }}>
              {errorMessage}
            </p>
          }
        
      </Box>
    </Modal>
  );
};
