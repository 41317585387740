import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UsersIcon from "@mui/icons-material/People";
import ProductionsIcon from "@mui/icons-material/Biotech";
import StudiesIcon from "@mui/icons-material/QueryStats";
import KitsIcon from "@mui/icons-material/Science";
import CentersIcon from "@mui/icons-material/Store";
import SponsorsIcon from "@mui/icons-material/Apartment";
import { Copyright } from "../Copyright";
import { store } from "../../store";
import { logoutAction } from "../../redux/actions/root";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Drawer } from "../ui/Drawer";
import AppBarComponent from "../ui/AppBar";
import renderTitle from "./Title";
import { getCenterData } from "../../redux/actions/center";

export const Protected = ({ children }) => {
  const [, setStudySelected] = React.useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const adminUser = user.role === "admin";
  const cdlUser = user.organization === "CDL";
  const employeeUser = user.role === "employee" && cdlUser;
  const sponsorUser = user.organization === "Sponsor";
  const centerUser = user.organization === "Center";
  const monitorUser = user.role === "monitor";
  const center = useSelector((state) => state.center.center);
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const studyCode = centerUser
    ? center?.Study?.sponsorStudyCode
    : location.state?.studyCode;
  const studyId = location.state?.studyId;
  const centerNumber = centerUser
    ? center?.centerNumber
    : location.state?.centerNumber;
  const centerId = centerUser ? user.centerId : location.state?.centerId;

  const showTitle = renderTitle(
    location,
    studyCode,
    centerNumber,
    centerUser,
    centerId
  );

  React.useEffect(() => {
    if (centerUser) {
      store.dispatch(getCenterData(user.centerId));
    }
  }, [centerUser, user.centerId]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (!user) <CircularProgress />;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent
        open={open}
        toggleDrawer={toggleDrawer}
        showTitle={showTitle}
        user={user}
      />
      {!user ? (
        <CircularProgress></CircularProgress>
      ) : (
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <List>
            {/* LIST OF STUDIES */}
            {(cdlUser || sponsorUser) && (
              <ListItemButton
                selected={location.pathname === "/studies"}
                onClick={() => {
                  navigate("/studies");
                  setStudySelected(!!studyCode);
                }}
              >
                <ListItemIcon>
                  <StudiesIcon />
                </ListItemIcon>
                <ListItemText primary={"Studies"} />
              </ListItemButton>
            )}
            {(studyCode && !centerUser) && (
              <>
                <Divider />
                <ListItemButton
                  onClick={() => {
                    navigate(`/centers`, { state: { studyId, studyCode } });
                  }}
                  sx={{ paddingLeft: 2 }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      <StudiesIcon />
                    </ListItemIcon>
                    <Typography variant="h6"> {studyCode} </Typography>
                  </Box>
                  <Divider />
                </ListItemButton>
              </>
            ) }

            {studyCode && (cdlUser || sponsorUser) && !centerNumber && (
              <ListItemButton
                selected={location.pathname === "/centers"}
                onClick={() => {
                  navigate(`/centers`, { state: { studyId, studyCode } });
                }}
                sx={{ paddingLeft: 4 }}
              >
                <ListItemIcon>
                  <CentersIcon />
                </ListItemIcon>
                <ListItemText primary={"Centers"} />
              </ListItemButton>
            )}

            {/*  CENTER SELECTED */}
            {centerNumber && !centerUser && (
              <>
                <Divider />
                <ListItemButton>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      <CentersIcon />
                    </ListItemIcon>
                    <Typography variant="h6">
                      {" "}
                      Center {centerNumber}{" "}
                    </Typography>
                  </Box>
                </ListItemButton>
              </>
            )}
            <Divider />
            {((studyCode && !monitorUser) ||
              centerUser ||
              (monitorUser && centerId)) && (
              <>
                <ListItemButton
                  selected={location.pathname === "/materials"}
                  onClick={() => {
                    navigate("/materials", {
                      state: { studyId, studyCode, centerNumber, centerId },
                    });
                  }}
                  sx={{ paddingLeft: 4 }}
                >
                  <ListItemIcon>
                    <KitsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Material List" />
                </ListItemButton>
                <Divider />
              </>
            )}

            {((studyCode && !monitorUser) ||
              centerUser ||
              (monitorUser && centerId)) && (
              <>
                <ListItemButton
                  selected={location.pathname === "/productions"}
                  onClick={() => {
                    navigate("/productions", {
                      state: { studyId, studyCode, centerNumber, centerId },
                    });
                  }}
                  sx={{ paddingLeft: 4 }}
                >
                  <ListItemIcon>
                    <ProductionsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      centerUser || location.state?.centerId
                        ? "Inventory"
                        : "Productions"
                    }
                  />
                </ListItemButton>

                <Divider />

                {!employeeUser && (
                  <ListItemButton
                    selected={
                      location.pathname === "/orders/" ||
                      location.pathname === "/order"
                    }
                    onClick={() => {
                      navigate("/orders/", {
                        state: { centerNumber, studyCode, studyId, centerId },
                      });
                    }}
                    sx={{ paddingLeft: 4 }}
                  >
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Orders" />
                  </ListItemButton>
                )}
                {adminUser && !centerNumber && (
                  <>
                    <Divider />
                    <ListItemButton
                      selected={location.pathname === "/study-info"}
                      onClick={() => {
                        navigate("/study-info", {
                          state: { studyCode, studyId },
                        });
                      }}
                      sx={{ paddingLeft: 4 }}
                    >
                      <ListItemIcon>
                        <StudiesIcon />
                      </ListItemIcon>
                      <ListItemText primary="Study Info" />
                    </ListItemButton>
                  </>
                )}

                {adminUser && centerNumber && (
                  <>
                    <Divider />
                    <ListItemButton
                      selected={location.pathname === "/center-info"}
                      onClick={() => {
                        navigate(`/center-info`, {
                          state: { centerNumber, studyCode, studyId, centerId },
                        });
                      }}
                      sx={{ paddingLeft: 4 }}
                    >
                      <ListItemIcon>
                        <CentersIcon />
                      </ListItemIcon>
                      <ListItemText primary="Center Info" />
                    </ListItemButton>
                  </>
                )}
                <Divider />
              </>
            )}

            {adminUser && (
              <>
                <ListItemButton
                  selected={location.pathname === "/sponsors"}
                  onClick={() => {
                    navigate("/sponsors");
                    setStudySelected(false);
                  }}
                >
                  <ListItemIcon>
                    <SponsorsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sponsors" />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  selected={location.pathname === "/users"}
                  onClick={() => {
                    navigate("/users");
                    setStudySelected(false);
                  }}
                >
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
                <Divider />
              </>
            )}
            {cdlUser && !employeeUser && (
              <ListItemButton
                selected={location.pathname === "/orders"}
                onClick={() => {
                  navigate("/orders");
                }}
              >
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
              </ListItemButton>
            )}
            <Divider />
            <ListItemButton
              onClick={() => {
                navigate("/");
                store.dispatch(logoutAction());
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
          <Grid container>
            <Outlet />
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
};
