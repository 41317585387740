import React from "react";
import { TextField, Box } from "@mui/material";
import { Grid } from "@mui/material";

// BANNER FOR USER INFO

const UserInfo = ({ title = "", user = {}, date = "" }) => (
  <Box mb={1}>
    <TextField
      value={title}
      InputProps={{
        readOnly: true,
      }}
      inputProps={{
        style: { textAlign: "center" },
      }}
      fullWidth
      style={{ marginBottom: "8px", marginTop: "16px" }}
    />
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          label="Name"
          value={user?.firstname + " " + user?.name || ""}
          InputProps={{
            readOnly: true,
          }}
          inputProps={{
            style: { textAlign: "center", padding: "10px 0" },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Email"
          value={user?.email || ""}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            style: { textAlign: "center", padding: "10px 0" },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Date"
          value={date}
          InputProps={{
            readOnly: true,
          }}
          inputProps={{
            style: { textAlign: "center", padding: "10px 0" },
          }}
          fullWidth
          
        />
      </Grid>
    </Grid>
  </Box>
);

export default UserInfo;
