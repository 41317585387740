import { ROOT } from "../constants";
import { instance, baseUrl } from "../../axios";
import { setUserAction, cleanUserAction } from "../actions/user";

// Public route
let loginAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ROOT.LOGIN_REQUEST,
    });
    instance({
      method: "POST",
      url: baseUrl + "/token",
      data: {
        ...user,
        ...{
          grant_type: "code",
        },
      },
    })
      .then((results) => {
        dispatch({
          type: ROOT.LOGIN_SUCCESS,
          payload: results.data,
        });
        dispatch(setUserAction(results.data.User));
      })
      .catch((error) => {
        dispatch({
          type: ROOT.LOGIN_FAILURE,
          payload: error,
        });
      });
  };
};

let logoutAction = () => {
  return (dispatch, getState) => {
    dispatch(cleanUserAction());
    dispatch({
      type: ROOT.LOGOUT,
    });
  };
};

export { loginAction, logoutAction };
