import logo from "../../../images/cdl-pharma.png";

export const generatePdfFromCanvas = (canvas, yOffset, pdf) => {
  const imgData = canvas.toDataURL("image/png");
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  const imageWidth = canvas.width;
  const imageHeight = canvas.height;

  const ratio =
    imageWidth / imageHeight >= (pageWidth - 20) / pageHeight
      ? (pageWidth - 20) / imageWidth // subtract 20 for left and right margins
      : pageHeight / imageHeight;

  const scaledWidth = imageWidth * ratio;
  const scaledHeight = imageHeight * ratio;

  pdf.addImage(imgData, "PNG", 10, yOffset, scaledWidth, scaledHeight);

  // Return the y-offset for the next element
  return yOffset + scaledHeight;
};

export const addLogoToPdf = (pdf) => {
  const logoWidth = 50;
  const logoAspectRatio = 310 / 63; // image dimensions
  const logoHeight = logoWidth / logoAspectRatio;
  pdf.addImage(logo, "PNG", 10, 10, logoWidth, logoHeight);
  return logoHeight;
};
