import { STUDY } from "../constants";
import { instance } from "../../axios";

let getStudies = () => {
  return (dispatch) => {
    dispatch({
      type: STUDY.STUDY_GET_ALL,
    });
    instance({
      method: "GET",
      url: "/study",
    })
      .then((results) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getStudiesBySponsor = (sponsorId) => {
  return (dispatch) => {
    dispatch({
      type: STUDY.STUDY_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/study/sponsor/${sponsorId}`,
    })
      .then((results) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
}

let getStudiesBySponsorUser = (userId) => {
  return (dispatch) => {
    dispatch({
      type: STUDY.STUDY_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/study/sponsor-user/${userId}`,
    })
      .then((results) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getStudiesByEmployee = (userId) => {
  return (dispatch) => {
    dispatch({
      type: STUDY.STUDY_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/study/employee/${userId}`,
    })
      .then((results) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STUDY.STUDY_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
}

let createStudy = (body) => {
  return (dispatch) => {
    dispatch({
      type: STUDY.STUDY_CREATE,
    });
    instance
      .post("/study", body)
      .then((results) => {
        dispatch({
          type: STUDY.STUDY_CREATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STUDY.STUDY_CREATE_FAILURE,
          payload: error,
        });
      });
  };
};

let updateStudy = (id, body) => {
  return (dispatch) => {
    dispatch({
      type: STUDY.STUDY_UPDATE,
    });
    instance
      .put(`/study/${id}`, body)
      .then((results) => {
        dispatch({
          type: STUDY.STUDY_UPDATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STUDY.STUDY_UPDATE_FAILURE,
          payload: error,
        });
      });
  };
}

const getStudyInfo = (studyId) => {
  return (dispatch) => {
    dispatch({
      type: STUDY.STUDY_GET_ONE,
    });
    instance({
      method: "GET",
      url: `/study/${studyId}/info`,
    })
      .then((results) => {
        dispatch({
          type: STUDY.STUDY_GET_ONE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STUDY.STUDY_GET_ONE_FAILURE,
          payload: error,
        });
      });
  }
}

const resetStudy = () => {
  return {
    type: STUDY.STUDY_CLEAR,
  };
};

export { createStudy, getStudies, getStudiesBySponsor, getStudiesBySponsorUser, getStudiesByEmployee, updateStudy, resetStudy, getStudyInfo };
