import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import { format } from "date-fns";
import * as React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function OrderTable({
  order,
  studyCode,
  centerNumber,
  centerId,
  cdlUser,
  studyId,
}) {
  const navigate = useNavigate();

  const handleShipmentDetails = (shipmentIndex) => {
    navigate("/shipping", {
      state: {
        order,
        orderId: order.id,
        centerNumber,
        studyCode,
        studyId,
        shipmentIndex,
        centerId,
      },
    });
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>Study</TableCell>
            <TableCell>Center Number</TableCell>
            <TableCell>Order Number</TableCell>
            <TableCell>Order Date</TableCell>
            {((cdlUser && order.status !== "placed") ||
              (!cdlUser && order.status === "confirmed")) && (
              <TableCell>Confirmation Date</TableCell>
            )}
            <TableCell>Status</TableCell>
            {order.shipments && order.shipments.length > 0 && (
              <>
                <TableCell>Sending Date</TableCell>
                <TableCell>Delivery Date</TableCell>
              </>
            )}
          </TableRow>
          <TableRow>
            <TableCell>{studyCode}</TableCell>
            <TableCell>{centerNumber}</TableCell>
            <TableCell>{order.orderNumber}</TableCell>
            <TableCell>
              {new Date(order.orderDate).toLocaleDateString("en-GB")}
            </TableCell>
            {((cdlUser && order.status !== "placed") ||
              (!cdlUser && order.status === "confirmed")) && (
              <TableCell>
                {new Date(order.confirmationDate).toLocaleDateString("en-GB")}
              </TableCell>
            )}
            <TableCell>{order.status}</TableCell>
            {order.shipments && order.shipments.length > 0 && (
              <>
                <TableCell>
                  {format(
                    new Date(order.shipments[0]?.sendingDate),
                    "dd/MM/yyyy"
                  )}
                </TableCell>
                <TableCell>
                  {order.shipments[0].deliveryDate
                    ? format(
                        new Date(order.shipments[0]?.deliveryDate),
                        "dd/MM/yyyy"
                      )
                    : "Not delivered yet"}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
      {order.shipments &&
        cdlUser &&
        order.shipments.map((shipment, index) => (
          <Table sx={{ minWidth: 650 }} aria-label="simple table" key={index}>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: "800", textAlign: "center" }}>
                  Shipment {index + 1}:
                </TableCell>
                <TableCell style={{ fontWeight: "800" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "800" }}>
                  Sending Date
                </TableCell>
                <TableCell style={{ fontWeight: "800" }}>
                  Delivery Date
                </TableCell>
                {cdlUser && (
                  <TableCell style={{ fontWeight: "800" }}>
                    Airwaybill
                  </TableCell>
                )}
                {shipment.comment && (
                  <TableCell style={{ fontWeight: "800" }}>Comment</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: "6px 16px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{
                      padding: "16px 24px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    onClick={() => handleShipmentDetails(index)}
                  >
                    shipping details
                  </Button>
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>
                  {shipment.status}
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>
                  {format(new Date(shipment.sendingDate), "dd/MM/yyyy")}
                </TableCell>
                <TableCell style={{ padding: "6px 16px" }}>
                  {shipment.deliveryDate
                    ? format(new Date(shipment.deliveryDate), "dd/MM/yyyy")
                    : "Not delivered yet"}
                </TableCell>
                {cdlUser && (
                  <TableCell style={{ padding: "6px 16px" }}>
                    {shipment.packingSlip}
                  </TableCell>
                )}
                {shipment.comment && (
                  <TableCell style={{ padding: "6px 16px" }}>
                    {shipment.comment}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        ))}
    </TableContainer>
  );
}
