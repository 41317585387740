import React from "react";

const LegalNotice = () => {
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Legal Notices</h2>
      <div>
        <h3>1. Ownership</h3>
        <p style={{ fontSize: "0.8rem" }}>
          CDL Pharma (hereinafter, "the owner"), a société par actions
          simplifiées (simplified joint stock company) registered with the
          Registrar of the Commercial Court of Marseille under number 481 806
          602, having its registered office at 34 rue Peyssonnel, 13003
          Marseille, France, subject to value-added tax, trading under number
          FR23481806602 and reachable at +33 4 96 20 96 20. The director and
          person responsible for the publication of this website is Dr. Chalom
          Sayada, in his capacity as Representative of Advanced Biological
          Laboratories (ABL) S.A., owner of CDL Pharma.
        </p>

        <h3>2. Technical Information</h3>
        <p style={{ fontSize: "0.8rem" }}>
          The user hereby acknowledges that they take cognizance of this legal
          notice and undertake to comply with it. The user hereby acknowledges
          that they have the skills and the means necessary to access and use
          this site. The user of the website hereby acknowledges that they have
          verified that the technical material it will use to connect to the
          site is virus-free and in perfect operating condition. This site is
          accessible 24 hours/day and 7 days/week, except in cases of force
          majeure, computer troubles, difficulties relating to the
          communications network structure or any other technical issues.
          Furthermore, for maintenance purposes, the Owner of the website may
          interrupt access to the site and will attempt to so inform the users
          prior to such interruption.
        </p>

        <h3>3. Site content</h3>
        <p style={{ fontSize: "0.8rem" }}>
          The information provided by CDL Pharma is for informational purposes
          only. CDL Pharma does not guarantee that the information provided on
          its site is accurate, complete or up-to-date. Consequently, the user
          hereby acknowledges that they use this information subject to assuming
          sole liability. The content of the site, its general structure and all
          texts, animated and still images, know-how, and all other elements
          constituting the site, are the exclusive property of CDL Pharma. Any
          partial or total representation of this site, by any procedure
          whatsoever, without the express consent of CDL Pharma, is prohibited
          and constitutes fraud as punishable under the Intellectual Property
          Code.
        </p>

        <h3>4. Hypertext link</h3>
        <p style={{ fontSize: "0.8rem" }}>
          CDL Pharma may reference, for informational purposes only, through
          hypertext links, other resources existing on the Internet. CDL Pharma
          undertakes, in the event of dispute and upon request only, to delete
          such hypertext link as promptly as possible. CDL Pharma may not be
          held liable for hypertext links added in the context of this site to
          other resources on the Internet network. Likewise, CDL Pharma may not
          be held liable for the access by users through hypertext links added
          in the context of the website to other resources on the Internet.
          Users and visitors of the website are free to link this site through a
          hypertext link and to put the link on their home page, subject to
          compliance with the spirit of the site. In any case, users who add
          such links to this site undertake to remove their link upon CDL
          Pharma’ simple request.
        </p>

        <h3>5. Authorization to reproduce the content</h3>
        <p style={{ fontSize: "0.8rem" }}>
          For any further information or requests to reproduce the content
          appearing on the CDL Pharma site (texts, graphics and illustrations),
          regardless of the media, the user may send such request by regular
          mail to the following address: CDL Pharma, 34 rue Peyssonnel, 13003
          Marseille, France.
        </p>
      </div>

      <h2 style={{ textAlign: "center", marginTop: "10rem" }}>Privacy Policy</h2>
      <p style={{ fontSize: "0.8rem" }}>
        At CDL Pharma, accessible from portal.cdlpharma.com, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by CDL
        Pharma and how we use it. If you have additional questions or require
        more information about our Privacy Policy, do not hesitate to contact
        us. This Privacy Policy applies only to our online activities and is
        valid for visitors to our website with regards to the information that
        they shared and/or collect in CDL Pharma. This policy is not applicable
        to any information collected offline or via channels other than this
        website.
      </p>
      <h3>Consent</h3>
      <p style={{ fontSize: "0.8rem" }}>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
      <h3>Information we collect</h3>
      <p style={{ fontSize: "0.8rem" }}>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information. If you contact us
        directly, we may receive additional information about you such as your
        name, email address, phone number, the contents of the message and/or
        attachments you may send us, and any other information you may choose to
        provide. When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </p>
      <h3>How we use your information</h3>
      <p style={{ fontSize: "0.8rem" }}>
        We use the information we collect in various ways, including to:
        Provide, operate, and maintain our website. Improve, personalize, and
        expand our website. Understand and analyse how you use our website.
        Develop new products, services, features, and functionality. Communicate
        directly with you including for customer service, to provide you with
        updates and other information relating to the website, and for marketing
        and promotional purposes. Send you emails. Find and prevent fraud.
      </p>
      <h3>Log Files</h3>
      <p style={{ fontSize: "0.8rem" }}>
        CDL Pharma follows a standard procedure of using log files. These files
        log visitors when they visit websites. All hosting companies do this and
        a part of hosting services analytics. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information
        that is personally identifiable. The purpose of the information is for
        analyzing trends, administering the site, tracking users movement on the
        website, and gathering demographic information.
      </p>
      <h3>Cookies</h3>
      <p style={{ fontSize: "0.8rem" }}>
        Like any other website, CDL Pharma uses cookies. These cookies are used
        to store information including visitors preferences, and the pages on
        the website that the visitor accessed or visited. The information is
        used to optimize the users experience by customizing our web page
        content based on visitors browser type and/or other information.
      </p>
      <h3>GDPR Data Protection Rights</h3>
      <p style={{ fontSize: "0.8rem" }}>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following: The right to
        access – You have the right to request copies of your personal data. The
        right to rectification – You have the right to request that we correct
        any information you believe is inaccurate. You also have the right to
        request that we complete the information you believe is incomplete. The
        right to erasure – You have the right to request that we erase your
        personal data, under certain conditions. The right to restrict
        processing – You have the right to request that we restrict the
        processing of your personal data, under certain conditions. The right to
        object to processing – You have the right to object to our processing of
        your personal data, under certain conditions. The right to data
        portability – You have the right to request that we transfer the data
        that we have collected to another organization, or directly to you,
        under certain conditions. If you would like to exercise any of these
        rights, please contact us (gdpr@ablsa.com).
      </p>
      <h3>Exercise your rights</h3>
      <p style={{ fontSize: "0.8rem" }}>
        For any information or exercise of your IT and Freedom rights on the
        processing of personal data managed by CDL Pharma, you can contact its
        data protection officer (DPO): gdpr@ablsa.com. Or by post (with a copy
        of your identity card if you wish to exercise your rights, unless the
        information provided in your request enables you to be identified with
        certainty) to the following address: CDL Pharma For the attention of the
        DPO 34 rue Peyssonnel 13003 Marseille, France
      </p>
    </div>
  );
};

export default LegalNotice;
