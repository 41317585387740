import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { createProduction } from "../../redux/actions/production";
import { store } from "../../store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getMaterials } from "../../redux/actions/material";
import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { showErrorAlert, showSuccessAlert } from "../ui/utils/AlertUtils";


export const ModalProduction = ({ visible, onClose, studyCode, studyId }) => {
  const isLoading = useSelector(
    (state) => state.production.isLoadingProduction
  );
  const created = useSelector((state) => state.production.createdProduction);
  const materials = useSelector((state) => state.material.materials);
  const [selectedMaterial, setSelectedMaterial] = React.useState(null);
  const [quantity, setQuantity] = React.useState("");
  const [productionDate, setProductionDate] = React.useState(null);
  const [expirationDate, setExpirationDate] = React.useState(null);
  const [isExpirationDateNA, setIsExpirationDateNA] = React.useState(false);
  const [batchNumber, setBatchNumber] = React.useState("");
  const [references, setReferences] = React.useState([]);
  const [sendingAuthorization, setSendingAuthorization] = React.useState(null);
  const [comment, setComment] = React.useState("");
  const [firstReference, setFirstReference] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const refName = `${studyCode?.replace("-", "").slice(2)}NA`;
  const [dateError, setDateError] = React.useState({
    expirationDate: false,
    sendingAuthorization: false,
  });

  const generateReferences = React.useCallback(() => {
    if (!firstReference || !quantity) {
      return [];
    }

    // If firstReference is "NA", return an array of "NA" of length quantity
    if (firstReference === refName) {
      return Array.from({ length: parseInt(quantity) }, () => refName);
    }

    // Split the firstReference into parts
    const match = firstReference.match(/^(.*?)(\d+)$/);
    if (!match) {
      return [];
    }

    const start = parseInt(match[2]);
    const firstPart = match[1];
    const count = parseInt(quantity);

    return Array.from({ length: count }, (_, i) => {
      // Pad the numeric part with zeros if necessary
      const numericPart = String(start + i).padStart(match[2].length, "0");
      return firstPart + numericPart;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstReference, quantity]);

  // update references when quantity changes
  React.useEffect(() => {
    setReferences(generateReferences());
  }, [generateReferences]);

  React.useEffect(() => {
    const validateDates = () => {
      let expirationDateError = false;
      let sendingAuthorizationError = false;

      if (productionDate && expirationDate) {
        expirationDateError = dayjs(expirationDate).isBefore(dayjs(productionDate));
      }

      if (productionDate && sendingAuthorization) {
        sendingAuthorizationError = dayjs(sendingAuthorization).isBefore(dayjs(productionDate)) || (expirationDate && dayjs(sendingAuthorization).isAfter(dayjs(expirationDate)));
      }

      setDateError({
        expirationDate: expirationDateError,
        sendingAuthorization: sendingAuthorizationError,
      });
    };

    validateDates();
  }, [productionDate, expirationDate, sendingAuthorization]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isMaterialType =
      selectedMaterial && selectedMaterial.type === "material";

    // if all fields are filled
    if (
      (isMaterialType &&
        batchNumber &&
        productionDate &&
        expirationDate &&
        sendingAuthorization &&
        selectedMaterial &&
        firstReference &&
        quantity) ||
      (!isMaterialType &&
        productionDate &&
        sendingAuthorization &&
        selectedMaterial &&
        quantity)
    ) {
      try {
        for (const reference of references) {
          const production = {
            batchNumber,
            productionDate,
            expirationDate,
            sendingAuthorization,
            comment,
            studyId,
            materialId: selectedMaterial.id,
            reference,
            status: "produced",
          };
          await store.dispatch(createProduction(production));
        }
        setBatchNumber("");
        setProductionDate(null);
        setExpirationDate(null);
        setSendingAuthorization(null);
        setComment("");
        setSelectedMaterial("");
        setFirstReference("");
        setQuantity("");
        setErrorMessage("");
        onClose(true);
      } catch (err) {
        showErrorAlert("An error occurred while creating the production.");
      }
    } else {
      setErrorMessage("Please fill all fields");
    }
  };

  React.useEffect(() => {
    if (created) {
      showSuccessAlert("The productions were successfully created");
    }
  }, [created]);

  React.useEffect(() => {
    store.dispatch(getMaterials(studyId));
  }, [studyId]);

  return (
    <Modal open={visible}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          flexGrow: 1,
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Create a production
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Please indicate the following fields to create a production
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-material">Material</InputLabel>
              <Select
                labelId="select-material"
                id="select-material"
                value={selectedMaterial ? selectedMaterial.id : ""}
                name="selectfield"
                label="Material"
                onChange={(event) => {
                  const newSelectedMaterial = materials.find(
                    (material) => material.id === event.target.value
                  );
                  setSelectedMaterial(newSelectedMaterial);
                }}
              >
                {materials.map((material, index) => (
                  <MenuItem key={index} value={material.id}>
                    {material.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                required={
                  selectedMaterial && selectedMaterial.type === "material"
                    ? true
                    : false
                }
                name="batchNumber"
                value={batchNumber}
                onChange={(event) => {
                  setBatchNumber(event.target.value);
                }}
                id="batch-number"
                label="Batch number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                required
                id="quantity"
                name="quantity"
                label="Quantity"
                type="number"
                value={quantity}
                onChange={(event) => {
                  setQuantity(event.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                required
                name="firstReference"
                value={firstReference}
                onChange={(event) => {
                  setFirstReference(event.target.value);
                }}
                id="firstReference"
                label="First Reference Number"
              />
            </FormControl>
            {selectedMaterial && selectedMaterial.type !== "material" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={firstReference === refName}
                    onChange={(event) => {
                      setFirstReference(event.target.checked ? refName : "");
                    }}
                  />
                }
                label="Reference is N/A"
              />
            )}
          </Grid>
          {firstReference && firstReference !== refName && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  name="references"
                  value={references.join(", ")}
                  id="references"
                  label="References"
                  multiline
                  rowsMax={4}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                label="Production date"
                name="productionDate"
                value={productionDate}
                onChange={(newValue) => {
                  setProductionDate(newValue);
                }}
                format="DD/MM/YYYY"
                maxDate={dayjs()}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                label="Expiration date"
                name="expirationDate"
                value={expirationDate}
                onChange={(newValue) => {
                  setExpirationDate(newValue);
                }}
                format="DD/MM/YYYY"
                sx={{ width: "100%" }}
                required={
                  selectedMaterial && selectedMaterial.type === "material"
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: dateError.expirationDate,
                    helperText: dateError.expirationDate ? "Expiration date must be after production date" : "",
                  },
                }}
              />
            </LocalizationProvider>
            {selectedMaterial && selectedMaterial.type !== "material" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isExpirationDateNA}
                    onChange={(event) => {
                      setIsExpirationDateNA(event.target.checked);
                      if (event.target.checked) {
                        setExpirationDate(dayjs("2999-01-01"));
                      }
                    }}
                  />
                }
                label="Expiration date is N/A"
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                label="Sending Authorization Date"
                name="sendingAuthorization"
                value={sendingAuthorization}
                onChange={(newValue) => {
                  setSendingAuthorization(newValue);
                }}
                format="DD/MM/YYYY"
                maxDate={dayjs()}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: dateError.sendingAuthorization,
                    helperText: dateError.sendingAuthorization ? "Sending Authorization must be after production and before expiration date" : "",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="comment"
                id="comment"
                label="Comment"
                multiline
                rows={2}
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="study-label">Study</InputLabel>
              <Select
                labelId="study-label"
                id="study-select"
                value={studyCode}
                input={<OutlinedInput label="Study" />}
                disabled
              >
                <MenuItem value={studyCode}>{studyCode}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isLoading}
          sx={{ mt: 3, mb: 2, padding: 2 }}
        >
          Create
        </Button>
        {errorMessage && (
          <p
            style={{
              color: "#721c24",
              backgroundColor: "#f8d7da",
              borderColor: "#f5c6cb",
              padding: ".75rem 1.25rem",
              marginBottom: "1rem",
              border: "1px solid transparent",
              borderRadius: ".25rem",
            }}
          >
            {errorMessage}
          </p>
        )}
      </Box>
    </Modal>
  );
};
