
export function generatePassword() {
        const length = 8; // minimum 8 characters
        const charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!\"#$%&'()*+,-./:;<=>?@[]^_`{|}~";
        const digits = "0123456789";
        const lowercase = "abcdefghijklmnopqrstuvwxyz";
        const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const special = "!\"#$%&'()*+,-./:;<=>?@[]^_`{|}~";
    
        let returnValue = "";
        returnValue += digits.charAt(Math.floor(Math.random() * digits.length));
        returnValue += lowercase.charAt(
          Math.floor(Math.random() * lowercase.length)
        );
        returnValue += uppercase.charAt(
          Math.floor(Math.random() * uppercase.length)
        );
        returnValue += special.charAt(Math.floor(Math.random() * special.length));
    
        for (let i = 4, n = charset.length; i < length; ++i) {
          returnValue += charset.charAt(Math.floor(Math.random() * n));
        }
    
        return returnValue;
}