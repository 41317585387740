import { instance } from "../axios";

export const createShipment = async (orderId, newShipment, details, user) => {
  const response = await instance({
    method: "POST",
    url: "/shipment",
    data: {
      orderId: orderId,
      preparationDate: newShipment?.preparationDate,
      sendingDate: newShipment?.sendingDate,
      carrierOrderDate: newShipment?.carrierOrderDate,
      carrier: newShipment?.carrier,
      packingSlip: newShipment?.packingSlip,
      packageDetails: details,
      shippedQuantity: newShipment?.shippedQuantity,
      status:
        new Date(newShipment?.sendingDate) < new Date()
          ? "shipped"
          : "programmed",
      shippedById: user.id,
      comment: newShipment?.comment,
    },
  });
  return response.data;
};

export const getShipmentById = async (shipmentId) => {
  const response = await instance.get(`/shipment/${shipmentId}`);
  return response.data;
};

export const updateShipmentStatus = async (shipment, status, comment) => {
  await instance.put(`/shipment/${shipment.id}`, {
    ...shipment,
    status: status,
    comment: comment,
  });
};

export const updateShipmentDelivery = async (
  shipmentId,
  status,
  deliveryDate
) => {
  await instance.put(`/shipment/${shipmentId}`, {
    status: status,
    deliveryDate: deliveryDate,
  });
};

export const updateShipmentComment = async (shipmentId, comment) => {
  await instance.put(`/shipment/${shipmentId}`, {
    comment: comment,
  });
}

export const updateShipmentReceived = async (shipmentId, userId, confirmedDate) => {
  await instance.put(`/shipment/${shipmentId}`, {
    confirmedDeliveryDate: confirmedDate,
    receivedById: userId,
  });
};
