import { STUDY } from "../constants";

const initialState = {
  studies: [],
  study: null,
  isLoadingStudies: false,
  isLoadingStudy: false,
  createdStudy: false,
  updatedStudy: false,
  error: null,
};

const studyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STUDY.STUDY_GET_ALL:
      return {
        ...state,
        tokenAccess: null,
        isLoadingStudies: true,
        createdStudy: false,
      };
    case STUDY.STUDY_GET_ALL_FAILURE:
      return {
        ...state,
        isLoadingStudies: false,
        createdStudy: false,
        error: action.payload,
      };
    case STUDY.STUDY_GET_ALL_SUCCESS:
      return {
        ...state,
        isLoadingStudies: false,
        studies: action.payload,
        createdStudy: false,
        error: null,
      };
    case STUDY.STUDY_GET_ONE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingStudy: true,
      };
    case STUDY.STUDY_GET_ONE_FAILURE:
      return {
        ...state,
        isLoadingStudy: false,
        error: action.payload,
      };
    case STUDY.STUDY_GET_ONE_SUCCESS:
      return {
        ...state,
        isLoadingStudy: false,
        study: action.payload,
        error: null,
      };
    case STUDY.STUDY_CREATE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingStudy: true,
        createdStudy: false,
      };
    case STUDY.STUDY_CREATE_FAILURE:
      return {
        ...state,
        isLoadingStudy: false,
        createdStudy: false,
        error: action.payload,
      };
    case STUDY.STUDY_CREATE_SUCCESS:
      return {
        ...state,
        isLoadingStudy: false,
        study: action.payload,
        createdStudy: true,
        error: null,
      };
    case STUDY.STUDY_UPDATE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingStudy: true,
        updatedStudy: false,
      };
    case STUDY.STUDY_UPDATE_FAILURE:
      return {
        ...state,
        isLoadingStudy: false,
        updatedStudy: false,
        error: action.payload,
      };
    case STUDY.STUDY_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingStudy: false,
        study: action.payload,
        updatedStudy: true,
        error: null,
      };
    case STUDY.STUDY_CLEAR:
      return {
        ...state,
        createdStudy: false,
        updatedStudy: false,
      };
    default:
      return state;
  }
};

export default studyReducer;
