import { ORDER } from "../constants";
import { instance } from "../../axios";

let getOrder = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: ORDER.ORDER_GET_ONE,
    });
    return instance({
      method: "GET",
      url: `/order/${orderId}`,
    })
      .then((results) => { 
        // set studycode + 
        dispatch({
          type: ORDER.ORDER_GET_ONE_SUCCESS,
         
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_GET_ONE_FAILURE,
          payload: error,
        });
      });
  };
};

let getOrders = () => {
  return (dispatch) => {
    dispatch({
      type: ORDER.ORDER_GET_ALL,
    });
    instance({
      method: "GET",
      url: "/order",
    })
      .then((results) => {
        dispatch({
          type: ORDER.ORDER_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getOrdersbyCenter = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: ORDER.ORDER_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/order/center/${centerId}`,
    })
      .then((results) => {
        dispatch({
          type: ORDER.ORDER_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getOrdersbyStudy = (studyId) => {
  return (dispatch) => {
    dispatch({
      type: ORDER.ORDER_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/order/study/${studyId}`,
    })
      .then((results) => {
        dispatch({
          type: ORDER.ORDER_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let createOrder = (body) => {
  return (dispatch) => {
    dispatch({
      type: ORDER.ORDER_CREATE,
    });
    instance
      .post("/order", body)
      .then((results) => {
        dispatch({
          type: ORDER.ORDER_CREATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_CREATE_FAILURE,
          payload: error,
        });
      });
  };
};

let updateOrder = (orderId, body) => {
  return (dispatch) => {
    dispatch({
      type: ORDER.ORDER_UPDATE,
    });
    instance
      .put(`/order/${orderId}`, body)
      .then((results) => {
        dispatch({
          type: ORDER.ORDER_UPDATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_UPDATE_FAILURE,
          payload: error,
        });
      });
  };
}

// change supplies quantity before order confirmed
let editOrder = (supplyId, body) => {
  return (dispatch) => {
    dispatch({
      type: ORDER.ORDER_UPDATE,
    });
    instance
      .put(`/supply/${supplyId}`, body)
      .then((results) => {
        dispatch({
          type: ORDER.ORDER_UPDATE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ORDER.ORDER_UPDATE_FAILURE,
          payload: error,
        });
      });
  };
}


export { createOrder, editOrder, getOrder, getOrders, getOrdersbyCenter, getOrdersbyStudy, updateOrder };
