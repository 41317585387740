import { ORDER } from "../constants";

const initialState = {
  orders: [],
  order: null,
  isLoadingOrders: false,
  isLoadingOrder: false,
  createdOrder: false,
  updatedOrder: false,
  error: null,
};

const orderReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ORDER.ORDER_GET_ONE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingOrder: true,
        createdOrder: false,
      };
    case ORDER.ORDER_GET_ONE_FAILURE:
      return {
        ...state,
        isLoadingOrder: false,
        createdOrder: false,
        error: action.payload,
      };
    case ORDER.ORDER_GET_ONE_SUCCESS:
      return {
        ...state,
        isLoadingOrder: false,
        order: action.payload,
        createdOrder: false,
        error: null,
      };
    case ORDER.ORDER_GET_ALL:
      return {
        ...state,
        isLoadingOrders: true,
        createdOrder: false,
      };
    case ORDER.ORDER_GET_ALL_FAILURE:
      return {
        ...state,
        isLoadingOrders: false,
        createdOrder: false,
        error: action.payload,
      };
    case ORDER.ORDER_GET_ALL_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        orders: action.payload,
        createdOrder: false,
        error: null,
      };
    case ORDER.ORDER_CREATE:
      return {
        ...state,
        isLoadingOrder: true,
        createdOrder: false,
      };
    case ORDER.ORDER_CREATE_FAILURE:
      return {
        ...state,
        isLoadingOrder: false,
        createdOrder: false,
        error: action.payload,
      };
    case ORDER.ORDER_CREATE_SUCCESS:
      return {
        ...state,
        isLoadingOrder: false,
        order: action.payload,
        createdOrder: true,
        error: null,
      };
    case ORDER.ORDER_UPDATE:
      return {
        ...state,
        tokenAccess: null,
        isLoadingOrder: true,
        updatedOrder: false,
      };
    case ORDER.ORDER_UPDATE_FAILURE:
      return {
        ...state,
        isLoadingOrder: false,
        updatedOrder: false,
        error: action.payload,
      };
    case ORDER.ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingOrder: false,
        order: action.payload,
        updatedOrder: true,
        error: null,
      };
    default:
      return state;
  }
};

export default orderReducer;
